<template>
    <div>
        <div class="form-inline mb-2">
            <label for="name" class="mr-4">Suche nach Teamname:</label>
            <input class="form-control" type="text" v-model="name" id="name" @keyup="search()">
        </div>
        <div class="ergebnisse">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="w-60">Name</th>
                        <th class="w-60">Spielort</th>
                        <th class="w-60">Captain</th>
                        <th class="w-20 text-center">Hinzuf&uuml;gen</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="team in teams" v-bind:key="team.id">
                    <td>{{team.name}}</td>
                    <td>{{team.location ? team.location.name : ''}}</td>
                    <td>{{team.captain ? team.captain.name : ''}}</td>
                    <td class="text-center">
                        <form method="post" @submit.prevent="add(team)">
                            <button type="submit" class="btn btn-primary">Hinzuf&uuml;gen</button>
                        </form>
                    </td>
                </tr>
                <tr v-if="teams.length == 0">
                    <td colspan="4" class="text-center">Bitte nach Teamname suchen oder keine Ergebnisse gefunden.</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: '',
                teams: []
            }
        },
        props: {
            league: Object
        },
        methods: {
            search() {
                axios.get("/leagueteams?name="+this.name)
                    .then(this.onSuccess)
            },
            onSuccess(response) {
                this.teams = response.data.teams;
            },
            add(team) {
                axios.post('/leagueteams/'+this.league.id+'/'+team.id)
                    .then(this.onSuccessAdd)
            },
            onSuccessAdd() {
                window.location.reload();
            }
        }

    }
</script>
