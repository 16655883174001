<template>
    <form-builder :url="url" :method="method" :fields="fields" :success="onSuccess"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
         methods: {
            onSuccess() {
                this.$root.$emit('updateChatMessages');
                $(".clickLoader").removeClass("disabled");
                $(".clickLoader .spinner-border").remove();
                $("textarea[name='message']").val("");
            }
        },
        data() {
            return {
                url: '/messages/',
                method: 'post',
                fields: [
                    {type: 'hidden', name: 'message_group_id', value: this.args.message_group_id},
                    {type: 'textarea', name: 'message', label: 'Nachricht:'},
                    {type: 'submit', name: 'Senden'}
                ]
            }

        }
    }
</script>
