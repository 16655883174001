<template>
    <form-builder :url="url" :method="method" :fields="fields" :argsdata="args.user"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        created() {
            this.args.user.password = this.password;
        },
        methods: {
           generatePW() {
               return Array(20).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$')
                        .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (x.length + 1))]).join('');
           }
        },
        data() {
            return {
                url: '/users/',
                method: 'post',
                password: this.generatePW(),
                fields: [
                    {type: 'text', name: 'name', label: 'Name'},
                    {type: 'text', name: 'email', label: 'E-Mail Adresse'},
                    {type: 'password', name: 'password', label: 'Passwort (kann geaendert werden)', value: this.password},
                    {type: 'submit', name: 'Hinzufügen'}
                ]
            }
            
        }
    }
</script>