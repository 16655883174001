<template>
    <line-chart :chart-data="datacollection" :options="options" :height="200"></line-chart>
</template>

<script>
  import LineChart from '../linechart.js'

  export default {
    components: {
      LineChart
    },
    props: {
        averages: Array,
    },
    data () {
      return {
        datacollection: null,
        options: {
          aspectRatio: 5,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                  beginAtZero: true,
                  steps: 12,
                  stepSize: 5,
                  max: 60
              }
            }]
          }
        }
      }
    },
    created () {
      this.fillData();
    },
    methods: {
      fillData () {
        this.datacollection = {
          labels: this.createLabels(),
          datasets: [
            {
              label: 'Average per Dart',
              backgroundColor: '#6574cd',
              borderColor: '#6574cd',
              borderWidth: 3,
              pointRadius: 6,
              data: this.createData(),
              fill: false,
              lineTension: 0,
            },
            {
              label: 'Average all Darts',
              backgroundColor: 'rgba(255,0,0,0.4)',
              borderColor: 'rgba(255,0,0,0.4)',
              borderWidth: 2,
              pointRadius: 0,
              data: this.createAverageAll(),
              fill: false,
              lineTension: 0,
            }
          ]
        }
      },
      createLabels() {
          return this.averages.map(average => average.date);
      },
      createData() {
          return this.averages.map(average => average.avg);
      },
      createAverageAll() {
          var avg = this.averages.reduce((sum, average) => sum + average.avg, 0);
          avg = Math.round(avg / this.averages.length * 100) / 100;
          var returnArray = []
          for (let i = 0; i < this.averages.length; i++) {
              returnArray[i] = avg;
          }
          return returnArray
      }
    }
  }
</script>

<style>
</style>