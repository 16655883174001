<template>
    <div class="substitution">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="w-30 text-center">
                        Ausgewechselt
                    </th>
                    <th class="w-30 text-center">
                        Eingewechselt
                    </th>
                    <th class="w-20 text-center">
                        Ab Spiel
                    </th>
                    <th class="w-20 text-center">
                        Speichern
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="sub in matchsubs" v-bind:key="sub.id">
                    <td>{{sub.player_out.name}}</td>
                    <td>{{sub.player_in.name}}</td>
                    <td class="text-center">{{gamenumberhelper(sub.game_number)}}</td>
                    <td><a href="#" class="btn btn-danger btn-block" @click.prevent="onDelete(sub.id)">Entfernen</a></td>
                </tr>
            </tbody>
            <tr v-if="!match.report_checked">
                <td>
                    <div class="form-group">
                        <select class="form-control" name="player" id="player" v-model="player_out">
                            <option v-for="(option, index) in localPlayersTeam" v-bind:key="index+' '+option.id" :value="option.id">
                                {{option.name}}
                            </option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <select class="form-control" name="player" id="player" v-model="player_in">
                            <option v-for="(option, index) in localPlayersTeam" v-bind:key="index+' '+option.id" :value="option.id">
                                {{option.name}}
                            </option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <select class="form-control" name="player" id="player" v-model="game">
                            <option :value="i" v-for="i in match['league']['gamecount_double']" v-bind:key="i">Doppel {{i}}</option>
                            <option :value="(i+match['league']['gamecount_double'])" v-for="i in match['league']['gamecount_single']" v-bind:key="(i+match['league']['gamecount_double'])">Einzel {{i}}</option>
                        </select>
                    </div>
                </td>
                <td class="text-center">
                    <a href="#" class="btn btn-primary btn-block" @click.prevent="onSubmit()">Auswechseln</a>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            playersTeam: Array,
            match: Object,
        },
        data() {
            return {
                url: '/substitutions',
                player_in: 0,
                player_out: 0,
                game: -1,
                localPlayersTeam: this.playersTeam
            }
        },
        mounted() {
        },
        computed: {
            matchsubs() {
                var subs = [];
                this.match.substitutions.forEach(sub => {
                    this.playersTeam.forEach(player => {
                        if (sub.player_out_id == player.id) {
                            subs.push(sub);
                        }
                    });
                });
                return subs;
            }
        },
        methods: {
            gamenumberhelper(num) {
                if (this.match.games.length == 18) {
                    if (num <=2 ) return "Doppel "+num;
                    else return "Einzel "+(num-2);
                } else if (this.match.games.length == 20) {
                    if (num <=4 ) return "Doppel "+num;
                    else return "Einzel "+(num-4);
                }
            },
            onSubmit () {
                var data = {
                    'player_in_id': this.player_in,
                    'player_out_id': this.player_out,
                    'match_id': this.match['id'],
                    'game_number': this.game
                }
                axios.post(this.url, data)
                .then(response => {
                    this.$parent.callSiteReload();
                    location.reload();
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error
                    })
                })
            },
            onDelete(id) {
                axios.delete("/substitutions/"+id)
                .then(response => {
                    this.$parent.callSiteReload();
                    location.reload();
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error
                    })
                })
            }
        }
    }
</script>
