<template>
    <select name="filter" id="filterform" v-model="searchstring" @change.prevent="onSubmit" class="searchform-input">
        <option v-for="(option, index) in options" :key="index" :value="option.value">{{option.value}}</option>
    </select>
</template>

<script>
    export default {
        props: {
            url: String,
            container: String,
            value: String,
        },
        data() {
            return {
                searchstring: this.value ? this.value : "Alle Spiele",
                options: [
                    {value:"Alle Spiele"},
                    {value:"Dillenburg"},
                    {value:"Wetzlar"},
                ]
            }
        },
        methods: {
            onSubmit () {
                axios.post(this.url, {"search": this.searchstring})
                .then(response => {
                    // $(this.container).html(response.data);
                    // $("ul.pagination").hide();
                    //reload current page
                    window.location.href = window.location.href;
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        }
    }
</script>
