<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/roles/',
                method: 'post',
                fields: [
                    {type: 'text', name: 'name', label: 'Name'},
                    {type: 'submit', name: 'Hinzufügen'}
                ]
            }
            
        }
    }
</script>