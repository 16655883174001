<template>
<div class="spielplanSpiel row">
    <div class="col-12">
        <h4>Doppel Spiel Nr. {{spielnummer}}</h4>
        <table class="table text-center match-table ml-1 mr-1">
            <thead>
                <tr>
                    <th colspan="2">
                        <div :class="{'winner': gamesHome == 2}">
                            {{playerHome.name}}<br>
                            {{playerHome2.name}}
                        </div>
                    </th>
                    <th colspan="2">
                    <div :class="{'winner': gamesGuest == 2}">
                        {{playerGuest.name}}<br>
                        {{playerGuest2.name}}
                        </div>
                    </th>
                </tr>
                
                <tr class="saetze">
                    <td colspan="2" class="" :class="{'winner': gamesHome == 2}">
                        <span v-if="double_with_legs">{{gamesHome}}</span>
                        <span v-else>
                            <input  @click="selectField" type="number" v-model="gamesHome">
                        </span>
                    </td>
                    <td colspan="2" class="" :class="{'winner': gamesGuest == 2}">
                        <span v-if="double_with_legs">{{gamesGuest}}</span>
                        <span v-else>
                            <input @click="selectField"  type="number" v-model="gamesGuest">
                        </span>
                    </td>
                </tr>
                <tr v-if="double_with_legs">
                    <th class="w-25">Darts</th>
                    <th class="w-25">Rest</th>
                    <th class="w-25">Darts</th>
                    <th class="w-25">Rest</th>
                </tr>
            </thead>
            <tbody v-if="double_with_legs">
                <tr :class="{'winner-home': game1guest.rest != 0, 'winner-away': game1home.rest != 0}">
                    <td><input @click="selectField" type="number" v-model="game1home.darts"></td>
                    <td><input @click="selectField" type="number" v-model="game1home.rest"></td>
                    <td><input @click="selectField" type="number" v-model="game1guest.darts"></td>
                    <td><input @click="selectField" type="number" v-model="game1guest.rest"></td>
                </tr>
                <tr :class="{'winner-home': game2guest.rest != 0, 'winner-away': game2home.rest != 0}">
                    <td><input @click="selectField" type="number" v-model="game2home.darts"></td>
                    <td><input @click="selectField" type="number" v-model="game2home.rest"></td>
                    <td><input @click="selectField" type="number" v-model="game2guest.darts"></td>
                    <td><input @click="selectField" type="number" v-model="game2guest.rest"></td>
                </tr>
                <tr :class="{'winner-home': game3guest.rest != 0, 'winner-away': game3home.rest != 0}">
                    <td><input @click="selectField" type="number" v-model="game3home.darts"></td>
                    <td><input @click="selectField" type="number" v-model="game3home.rest"></td>
                    <td><input @click="selectField" type="number" v-model="game3guest.darts"></td>
                    <td><input @click="selectField" type="number" v-model="game3guest.rest"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
  components: {
  },
  props: {
        homeIndex: Number,
        guestIndex: Number,
        homeIndex2: Number,
        guestIndex2: Number,
        spielnummer: Number,
        game: Object,
        double_with_legs: Number
  },
  created() {
      if (this.game) {
        this.playerHome = this.game.player1;
        this.playerGuest = this.game.player2;
        this.playerHome2 = this.game.player3;
        this.playerGuest2 = this.game.player4;
        this.gamesWonHome = this.game.home_won;
        this.gamesWonAway = this.game.away_won;
        this.$emit('input', this.generateMatchData());
      }
  },
  mounted() {
      if (this.game && this.game.legs) {
          if (this.game.legs[0]) {
            this.game1home = {darts: this.game.legs[0].player1_darts, rest: this.game.legs[0].player1_rest}
            this.game1guest = {darts: this.game.legs[0].player2_darts, rest: this.game.legs[0].player2_rest}
          }
          if (this.game.legs[1]) {
            this.game2home = {darts: this.game.legs[1].player1_darts, rest: this.game.legs[1].player1_rest}
            this.game2guest = {darts: this.game.legs[1].player2_darts, rest: this.game.legs[1].player2_rest}
          }
          if (this.game.legs[2]) {
            this.game3home = {darts: this.game.legs[2].player1_darts, rest: this.game.legs[2].player1_rest}
            this.game3guest = {darts: this.game.legs[2].player2_darts, rest: this.game.legs[2].player2_rest}
          }
      }
  },
  data() {
    return {
        playerHome: {},
        playerGuest: {},
        game1home: {darts: 0, rest: 0},
        game1guest: {darts: 0, rest: 0},
        game2home: {darts: 0, rest: 0},
        game2guest: {darts: 0, rest: 0},
        game3home: {darts: 0, rest: 0},
        game3guest: {darts: 0, rest: 0},
        gamesWonHome: 0,
        gamesWonAway: 0,
        playerHome: {},
        playerHome2: {},
        playerGuest: {},
        playerGuest2: {},
        winner: 0
    }
  },
  computed: {
     gamesHome: {
         get: function() {
             if (this.double_with_legs > 0) {
                var games = 0;
                games += this.game1home.rest == 0 && this.game1guest.rest != 0 ? 1 : 0;
                games += this.game2home.rest == 0 && this.game2guest.rest != 0 ? 1 : 0;
                games += this.game3home.rest == 0 && this.game3guest.rest != 0 ? 1 : 0;
                this.onGamesChange(this.playerHome.id, games);
                return games;
            } else return this.gamesWonHome;
         },
         set: function(value) {
             this.gamesWonHome = value;
             this.onGamesChange(this.playerHome.id, this.gamesWonHome);
         }
     },
     gamesGuest: {
        get: function() {
        if (this.double_with_legs > 0) {
            var games = 0;
            games += this.game1guest.rest == 0 && this.game1home.rest != 0 ? 1 : 0;
            games += this.game2guest.rest == 0 && this.game2home.rest != 0 ? 1 : 0;
            games += this.game3guest.rest == 0 && this.game3home.rest != 0 ? 1 : 0;
            this.onGamesChange(this.playerGuest.id, games);
            return games;
        } else return this.gamesWonAway;
         },
         set: function(value){
             this.gamesWonAway = value;
             this.onGamesChange(this.playerGuest.id, this.gamesWonAway);
         }
     },
  },
  methods: {
       selectField() {
        document.execCommand("selectall",null,false);
      },
      onGamesChange(team, games) {
          if (this.double_with_legs && games == 2 && this.winner == 0) {
            this.winner = team
            this.$emit('input', this.generateMatchData());
          } else {
            if (games >= 2) this.winner = team
            this.$emit('input', this.generateMatchData());
          }
      },
      generateMatchData() {
        var data = {};
        data['game_id'] = this.game.id;
        data['homeplayer'] = this.playerHome;
        data['guestplayer'] = this.playerGuest;
        if (this.double_with_legs) {
            data['legs'] = [];
            data['legs'].push([this.game1home, this.game1guest]);
            data['legs'].push([this.game2home, this.game2guest]);
            data['legs'].push([this.game3home, this.game3guest]);
        } else {
            data['gamesWonHome'] = this.gamesWonHome;
            data['gamesWonAway'] = this.gamesWonAway;
            data['legs'] = [];
        }
        data['winner'] = this.winner;
        return data;
      }
  }
}
</script>

<style lang="scss" scoped>
.spielplanSpiel {
    margin: 2vh 0 2vh 0;
}

input[type=number] {
    width: calc(100%);
    text-align: right;
}
</style>
