<template>
<table class="table table-striped">
    <thead class="thead-dark">
        <tr>
            <th class="w-30">Spielername</th>
            <th class="w-20">Team</th>
            <th class="w-30">Spielst&auml;tte</th>
            <th class="w-10">Nummer</th>
            <th class="w-10">Speichern</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="player in playersInTable" v-bind:key="player.id">
            <td>{{player.name}}</td>
            <td>{{player.team.name}}</td>
            <td>{{player.team.location.name}} {{player.team.location.ort}}</td>
            <td><input type="text" name="nummer" v-model="playersnumbers[player.id]"></td>
            <td><input type="submit" value="Speichern" @click.prevent="onSubmit(player.id)"></td>        
        </tr>
    </tbody>
    </table>
</template>

<script>
    export default {
        props: {
           players: Array
        },
        data() {
            return {
                playersnumbers: []
            }
        },
        created() {
            this.playersInTable = this.players;
        },
        methods: {
            onSubmit (id) {
                axios.post("/admin/store_players_without_nr", {"id": id, "passnr":this.playersnumbers[id]})
                .then(response => {
                    if (response.data && response.data.message == "success") {
                        //remove player from players
                        var newplayers = [];
                        this.playersInTable.forEach(p => {
                            if (p.id != id) newplayers.push(p);
                        });
                        this.playersInTable = newplayers;
                        this.$forceUpdate();
                    }
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        }
    }
</script>
