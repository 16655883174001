<template>
    <form-builder :url="url" :method="method" :fields="fields" :argsdata="args.role"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/roles/' + this.args.role.id,
                method: 'patch',
                 fields: [
                    {type: 'text', name: 'name', label: 'Name'},
                    {type: 'submit', name: 'Speichern'}
                ]
            }
            
        }
    }
</script>