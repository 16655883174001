<template>
    <a :href="href" :class="styles" @click.prevent="onSubmit"><slot></slot></a>
</template>

<script>
    export default {
        props: {
            href: "",
            container: "",
            styles: "",
        },
        data() {
            return {
            }
        },
        methods: {
            onSubmit () {
                axios.get(this.href)
                .then(response => {
                    $(this.container).html(response.data);
                    //reload ajax links
                    $('a.ajax_get').on('click',function(event) { 
                        event.preventDefault(); 
                        $.ajax({
                            url: $(this).attr('href'),
                            success: function(response) {
                                $($(event.target).attr("data-container")).html(response);
                            }
                        });
                        return false;
                    });
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        }
    }
</script>
