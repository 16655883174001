<template>
    <div>
        <div class="mb-2">
            <div class="">
            <label for="passnr" class="mr-4">Deine Pass Nr:</label>
            <input class="form-control" type="text" v-model="passnr" id="passnr">
            </div>
            <div>
            <label for="bday" class="mr-4">Dein Geburtsdatum (dd.mm.yyyy):</label>
            <input class="form-control" type="text" v-model="bday" id="bday">
            </div>
            <div>
            <label for="email" class="mr-4">Die Email Adresse mit der du deinen hdsv.de Benutzer angelegt hast:</label>
            <input class="form-control" type="text" v-model="email" id="email"><br>
            </div>
            <a href="#" @click.prevent="search()" class="btn btn-primary">Jetzt suchen</a>
        </div>
        <div class="ergebnisse">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="w-20">Pass Nr.</th>
                        <th class="w-60">Name</th>
                        <th class="w-20 text-center">Verbinden</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="player in players" v-bind:key="player.id">
                    <td>{{player.passnr}}</td>
                    <td>{{player.name}}</td>
                    <td class="text-center">
                        <form method="post" @submit.prevent="link(player)">
                            <button type="submit" class="btn btn-primary">Verbinden</button>
                        </form>
                    </td>
                </tr>
                <tr v-if="players.length == 0">
                    <td colspan="3" class="text-center">Keine Ergebnisse gefunden.</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                passnr: '',
                bday: '',
                email: '',
                players: []
            }
        },
        props: {
            team: Object
        },
        methods: {
            search() {
                var data = {passnr: this.passnr, bday: this.bday, email: this.email};
                axios.post("/users/lookupplayer", data)
                    .then(this.onSuccess)
            },
            onSuccess(response) {
                this.players = [];
                if (response.data.name)
                    this.players = [response.data];
            },
            link(player) {
                axios.post('/users/link/'+player.id, [{player_id: player.id}])
                    .then(this.onSuccessAdd)
            },
            onSuccessAdd() {
                window.location.reload();
            }
        }

    }
</script>
