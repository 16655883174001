<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                //url: '/teams/' + this.args.teamid + '/players',
                url: '/players/',
                method: 'post',
                fields: [
                    {type: 'text', name: 'name', label: 'Spielername'},
                    {type: 'text', name: 'passnr', label: 'Pass Nr.'},
                    {type: 'select', name: 'gender', label: 'Geschlecht', options: [{id: 'm', name:'Herr'}, {id: 'f', name:'Dame'}], noblank: true},
                    {type: 'submit', name: 'Hinzufügen'}
                ]
            }
            
        }
    }
</script>