<template>
  <div class="spielplanTeam col-12">
    <div class="row">
        <div class="col-10 offset-2 text-right">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="teamNoshow" @change="checkNoShow">
                    Team nicht angetreten&nbsp; &nbsp;
                </label>
            </div>
        </div>
    </div>
      <ul class="p-0" v-if="!teamNoshow">
          <li>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-2">Passnr.</div>
                <div class="col-8">Name</div>
              </div>
          </li>
          <li v-for="(n,index) in 10" v-bind:key="n">
            <div class="row">
                <div class="col-2 text-right">{{tag}}{{(index+1)}}</div>
                <div class="col-10">
                    <select @input="handleSelect(index, $event)" class="form-control">
                        <option
                            v-for="option in playersTeam"
                            v-bind:value="option.id"
                            v-bind:key="tag+(index+1)+option.id"
                            :selected="checkSelection(option, index)"
                            :class="checkSelectedTeam(option.id)"
                        >
                            {{option.passnr}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{option.name}}
                        </option>
                    </select>
                </div>
            </div>
          </li>
      </ul>
      <div class="card mt-4" v-else>
            <div class="card-body">
                Dieses Team ist nicht zum Spiel angetreten. Beim Speichern des Spielberichts wird das Spiel 18:0 (BZO entsprechend angepasst) f&uuml;r das angetretene Team gewertet.
                Bitte keine Spielergebnisse in den Bericht eintragen.
            </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'spielplanTeam',
  components: {
  },
  props: {
      homeTeam: String,
      playersTeam: Array,
      currentplayers: Array,
      noshow: String
  },
  data() {
    return {
        players: [],
        teamNoshow: false
    }
  },
  computed: {
      tag() {
          return this.homeTeam == "true" ? "H" : "G";
      }
  },
  mounted() {
      if (this.homeTeam == "true" && this.noshow == "H") this.teamNoshow = true;
      if (this.homeTeam == "false" && this.noshow == "G") this.teamNoshow = true;
    this.playersTeam.push({'name': 'Kein Spieler', id: '999999', 'passnr': '----------'});
    this.playersTeam.push({'name': '------', id: '-------', 'passnr': '-----'});
    if (this.currentplayers && this.currentplayers.length > 0) {
        for (let i = 0; i < this.currentplayers.length; i++) {
            this.playersTeam.forEach(p => {
                if (p.id == this.currentplayers[i].id) {
                    this.players.push(p);
                }
            });
        }
        for (let i = this.currentplayers.length; i < 10; i++) {
            this.players.push(this.playersTeam[this.playersTeam.length-1]);
        }
    } else {
        for (let i = 0; i < 5; i++) {
            this.players.push(this.playersTeam[this.playersTeam.length-1]);
        }
        for (let i = 5; i < 10; i++) {
            this.players.push(this.playersTeam[this.playersTeam.length-1]);
        }
    }
    this.$emit('input', this.players);
  },
  methods: {
      checkSelection(option, index) {
          return this.players[index] == option
      },
      handleSelect(index, event) {
          var newId = event.target.value;
          var player = null;
          this.playersTeam.forEach(element => {
              if (element != null && element.id == newId) player = element
          });
          this.players[index] = player
          this.$forceUpdate();
          this.$emit('input', this.players);
      },
      checkSelectedTeam(playerID) {
          var returnValue = "";
          this.players.forEach(p => {
              if (p.id == playerID && returnValue == "" && p.id != '----') returnValue = "alreadyInTeam";
          });
        return returnValue;
      },
      checkNoShow() {
          console.log("CHECK NOSHOW");
          if (this.teamNoshow) {
            this.$emit('brumm', this.tag);
          } else {
            this.$emit('brumm', "");
          }
      }
  }
}
</script>

<style lang="scss" scoped>
.spielplanTeam ul {
    text-align: left;
    list-style: none;
}
select {
    width: 100%;
    .alreadyInTeam {
        color: #DDD;
    }
}
</style>
