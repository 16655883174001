<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/players/' + this.args.player.id,
                method: 'patch',
                 fields: [
                    {type: 'text', name: 'name', label: 'Spielername', value: this.args.player.name},
                    {type: 'text', name: 'passnr', label: 'Pass Nr.', value: this.args.player.passnr},
                    {type: 'select', name: 'gender', label: 'Geschlecht', options: [{id: 'm', name:'Herr'}, {id: 'f', name:'Dame'}], value: this.args.player.gender, noblank: true},
                    {type: 'text', name: 'telefon', label: 'Telefon', value: this.args.player.telefon},
                    {type: 'checkbox', name: 'banned', label: 'Gesperrt', value: this.args.player.banned},
                    {type: 'checkbox', name: 'lvpbanned', label: 'LVP Gesperrt', value: this.args.player.lvpbanned},
                    {type: 'submit', name: 'Speichern'}
                ]
            }

        }
    }
</script>
