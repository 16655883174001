<template>
    <form-builder :url="url" :method="method" :fields="fields" ref='form'></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/matches/',
                method: 'post',
                teams: this.args.teams,
                fields: []
                
            }
        },
        mounted() {
            this.fields = this.createFormFields();
            this.$refs.form.reload();
        },
        methods: {
            createFormFields() {
                return [
                    {type: 'datetime', name: 'matchdate', label: 'Datum / Uhrzeit'},
                    {type: 'select', name: 'league_id', label: 'Liga', options: this.args.leagues, callback: this.updateTeams, noblank:true},
                    [
                        {type: 'select', name: 'hometeam_id', label: 'Heimteam', options: this.teams, noblank:true},
                        {type: 'select', name: 'awayteam_id', label: 'Gastteam', options: this.teams, noblank:true},
                    ],
                    {type: 'submit', name: 'Erstellen'}
                ];
            },
            updateTeams() {
                var league_id = this.$refs.form.getValue('league_id');
                if (league_id == undefined) league_id = '';
                axios.get('/matches/getTeams/' + league_id, {})
                    .then(response => {
                        this.teams = response.data;
                        this.fields = this.createFormFields();
                        this.$refs.form.reload();
                    })
                    .catch(error => {
                    })
            }
        }
    }
</script>