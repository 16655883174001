<template>
    <form :action="'/reports/'+match['id']" method="post" @submit.prevent="onSubmit">
        <div class="spinnerOverlay" v-if="siteReload"></div>
        <div class="waitText" v-if="siteReload && !siteReloadWithError"><i class="fas fa-spinner quickSpinner"></i> Bitte warten</div>
        <div class="waitText" v-if="siteReloadWithError"><i class="fas fa-spinner quickSpinner"></i> Fehler<span class="smallertext"><br>Die Seite wird neu geladen.<br>Bitte Eingaben überprüfen.</span></div>
        <div class="alert alert-info">Bitte vor dem Eintragen von Ergebnissen die Teams einstellen und auf "Teams speichern" klicken. Nach dem Editieren des Spielberichts nicht vergessen den Spielbericht zu speichern.</div>
            <div class="step step1 row">
                <div class="col-12">
                    <span class="label">1</span>
                    <span class="text">Teams einstellen</span>
                </div>
                <div class="description col-12">Stelle als erstes beide Teams wie auf dem Spielberichtsbogen vermerkt auf. Speichere danach die Teams &uuml;ber den Teams Speichern Button.</div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6">
                    <h5 class="text-center">{{match['hometeam']['name']}}</h5>
                    <report-form-edit-team
                        home-team="true"
                        :players-team="match['hometeam']['players']"
                        v-model="hometeamplayers"
                        :currentplayers="hometeamplayers"
                        :noshow="noshow"
                        @input="playerChange($event)"
                        @brumm="OnNoshow"
                    ></report-form-edit-team>
                </div>
                <div class="col-12 col-xl-6">
                    <h5 class="text-center">{{match['awayteam']['name']}}</h5>
                    <report-form-edit-team
                        home-team="false"
                        :players-team="match['awayteam']['players']"
                        v-model="awayteamplayers"
                        :currentplayers="awayteamplayers"
                        :noshow="noshow"
                        @input="playerChange($event)"
                        @brumm="OnNoshow"
                    ></report-form-edit-team>
                </div>

                <template v-if="noshow == ''">
                <div class="col-12 mb-2">
                    <button type="submit" class="btn btn-primary clickLoader" v-if="!match.report_checked">Teams speichern</button>
                </div>

                <div class="step step2 col-12">
                    <span class="label">2</span>
                    <span class="text">Auswechslungen</span>
                    <div class="description">Stelle nun ein, wer zu welchem Spiel aus und eingewechselt wurde. Ist die Person nicht in der Liste aufgef&uuml;hrt, speichere bitte nochmals die Teams.</div>
                </div>
                <div class="col-12 col-xl-6">
                    <report-form-edit-substitutions
                        :players-team="hometeamplayers_noblanks()"
                        :match="match"
                        :key="(matchKey)+'s0'"
                    ></report-form-edit-substitutions>
                </div>
                <div class="col-12 col-xl-6">
                    <report-form-edit-substitutions
                        :players-team="awayteamplayers_noblanks()"
                        :match="match"
                        :key="(matchKey)+'s1'"
                    ></report-form-edit-substitutions>
                </div>
                </template>
            </div>
            <template v-if="noshow == ''">
            <div class="step step3">
                <span class="label">3</span>
                <span class="text">Highscores eintragen</span>
                <div class="description">Trage nun ein, wer wieviele Highscores (171er und 180er) geworfen hat, sofern vermerkt. Falls Highfinishes aufgeschrieben wurden, k&ouml;nnen diese auch hier eingetragen werden. Pro Zeile bitte nur ein Highfinish eintrage. Ein Spieler kann mehrfach in den Highscores vorkommen (z.B. bei mehreren Highfinishes).</div>
            </div>
            <highscore-form :players="allteamplayers()" :match="match"></highscore-form>
            <div class="step step4">
                <span class="label">4</span>
                <span class="text">Bemerkungen eingeben</span>
                <div class="description">Sonstige &ouml;ffentliche Bemerkungen bitte hier angeben.</div>
            </div>
            <report-form-edit-notes :match="match"></report-form-edit-notes>
            <div class="step step5">
                <span class="label">5</span>
                <span class="text">Spielberichtsfoto hochladen</span>
                <div class="description">Mache ein Foto des Spielberichtsbogens, sobald er von beinen Mannschaften unterzeichnet wurde und lade diesen hier hoch. Am einfachsten als Bild hochladen.</div>
            </div>
            <report-form-file-upload :match="match"></report-form-file-upload>

            <div class="step step6">
                <span class="label">6</span>
                <span class="text">Spielergebnisse eintragen</span>
                <div class="description">
                    Nun k&ouml;nnen die Ergebnisse eingetragen werden. Sollten die Namen der Spieler nicht mit dem Spielbericht &uuml;bereinstimmen, &uuml;berpr&uuml;fe bitte die Teams sowie die Auswechslungen.<br>
                    Falls ein Spiel gewertet werden soll, ohne dass es gespielt wurde, bitte auf "Eingabe umschalten" dr&uuml;cken und das Ergbebnis 2-0 f&uuml;r den Sieger eintragen.
                </div>
                <hr>
                <span v-if="easyModeAllowed" class="text">Vereinfachte Spieleingabe</span>
                <div v-if="easyModeAllowed" class="description">
                    Du kannst diesen Bericht auch eintragen, ohne einzelne Legs aufschreiben zu m&uuml;ssen, da du in der C oder B Liga spielst. Dr&uuml;cke dazu auf den Knopf "Vereinfachte Eingabe umschalten". Falls ein einzelnes Spiel mit Legs eingetragen werden soll, beispielsweise f&uuml;r die Shortleg Wertung, dr&uuml;cke bei dem entsprechenden Spiel auf "Eingabe umschalten".<br>
                    <a href="#" class="btn btn-primary" @click.prevent="toggleEasyMode()">Vereinfachte Eingabe umschalten</a>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6" v-for="(row, index) in orderdouble" v-bind:key="index">
                    <report-form-edit-matchdouble
                        :key="(matchKey+index)"
                        v-model="doublematches[index]"
                        :game="editmatchesdouble[index]"
                        :spielnummer="(index+1)"
                        :homeIndex="row[0]"
                        :guestIndex="row[1]"
                        :homeIndex2="row[2]"
                        :guestIndex2="row[3]"
                        :double_with_legs="match.league.double_with_legs"
                        >
                    </report-form-edit-matchdouble>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-xl-6" v-for="(row, index) in order" v-bind:key="index">
                    <report-form-edit-match
                        :key="(matchKey+index)"
                        v-model="matches[index]"
                        :game="editmatches[index]"
                        :spielnummer="(index+1)"
                        :homeIndex="row[0]"
                        :guestIndex="row[1]"
                        :easyModeProp="easyMode">
                    </report-form-edit-match>
                </div>
            </div>
            </template>
            <div :class="['savebericht', noshow != '' ? 'inline' : '']">
                <button type="submit" class="btn btn-primary clickLoader" v-if="!match.report_checked">Spielbericht speichern</button>
                <button type="submit" class="btn btn-disabled" disabled="disabled" v-if="match.report_checked">Bericht gepr&uuml;ft, &Auml;nderungen nicht m&ouml;glich</button>
            </div>

    </form>
</template>

<script>
import ReportFormEditTeam from './ReportFormEditTeam';
    export default {
        props: {
            match: Object,
            homeplayers: Array,
            awayplayers: Array,
            order: Array,
            orderdouble: Array
        },
        data() {
            return {
                hometeamplayers: [],
                awayteamplayers: [],
                matches: [],
                doublematches: [],
                editmatches: [],
                matchKey: 10000000,
                noshow: this.match['noshow'],
                siteReload: false,
                siteReloadWithError: false,
                easyMode: false,
                easyModeAllowed: false,
            }
        },
        components: {
            ReportFormEditTeam
        },
        created() {
            this.editmatches = this.match['singlegames'];
            this.editmatchesdouble = this.match['doublegames'];
            this.hometeamplayers = this.homeplayers;
            this.awayteamplayers = this.awayplayers;
            this.easyModeAllowed = this.match.league && (this.match.league.ligatyp == 'B-Liga' || this.match.league.ligatyp == 'C-Liga')
            // this.easyModeAllowed = true //DEBUG Mode EasyMode
        },
        mounted() {
            if (localStorage && localStorage.getItem('matches_' + this.match.id)) {
                this.matches = JSON.parse(localStorage.getItem('matches_' + this.match.id));
                this.matches.forEach(m => {
                    if (m && m.game_id)
                    this.editmatches.forEach(s => {
                        if (s.id == m.game_id) {
                            for (let index = 0; index < m.legs.length; index++) {
                                const ml = m.legs[index];
                                if (!s.legs) s.legs = [];
                                if (!s.legs[index]) s.legs[index] = {};
                                s.legs[index]['player1_darts'] = ml[0]['darts'];
                                s.legs[index]['player1_rest'] = ml[0]['rest'];
                                s.legs[index]['player2_darts'] = ml[1]['darts'];
                                s.legs[index]['player2_rest'] = ml[1]['rest'];
                            }
                        }
                    });
                });
            }
        },
        watch: {
            matches: function(data) {
                if (localStorage) {
                    try {
                        localStorage.setItem('matches_' + this.match.id, JSON.stringify(data));
                    } catch (e) {
                        console.log("error");
                        console.log(e);
                        var key;
                        for (var i = 0; i < localStorage.length; i++) {
                            key = localStorage.key(i);
                            if(key.indexOf("matches") != -1 && key != 'matches_' + this.match.id) { localStorage.removeItem(key); }
                        }
                    }

                }
            }
        },
        methods: {
            OnNoshow(value) {
                console.log("NO SHOW CALLED (BRUMM)");
                this.noshow = value;
                if (!value) return;
            },
            allteamplayers() {
                var returnplayers = [];
                this.hometeamplayers.forEach(element => {
                    if (element.id != '-------') returnplayers.push(element);
                });
                this.awayteamplayers.forEach(element => {
                    if (element.id != '-------') returnplayers.push(element);
                });
                return returnplayers;
            },
            hometeamplayers_noblanks() {
                var return_players = []
                this.hometeamplayers.forEach(p => {
                    if (p.id != '-------')
                        return_players.push(p);
                });
                return return_players;
            },
            awayteamplayers_noblanks() {
                var return_players = []
                this.awayteamplayers.forEach(p => {
                    if (p.id != '-------')
                        return_players.push(p);
                });
                return return_players;
            },
            playerChange(test) {
                this.matchKey+=1;
                console.log("PLAYER CHANGE CALLED (INPUT)");
            },
            callSiteReload(withError = false) {
                this.siteReload = true;
                this.siteReloadWithError = withError;

            },
            toggleEasyMode() {
                this.easyMode = !this.easyMode;
                this.$root.$emit("toggleEasyMode", this.easyMode);
            },
            onSubmit (e) {
                console.log(this.matches);
                axios.post('/reports/'+this.match['id'], {
                    'hometeamplayers': this.hometeamplayers,
                    'awayteamplayers': this.awayteamplayers,
                    'games': this.matches,
                    'doublegames': this.doublematches,
                    'noshow': this.noshow,
                    '_method': 'PATCH'
                })
                .then(response => {
                    if (localStorage) {
                        localStorage.removeItem('matches_' + this.match.id);
                    }
                    this.callSiteReload();
                    location.reload();
                    Vue.notify({
                        group: 'foo',
                        title: 'Erfolg!',
                        text: 'Spielbericht erfolgreich gespeichert'
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                    if (localStorage) {
                        localStorage.removeItem('matches_' + this.match.id);
                    }
                    this.callSiteReload(true);
                    location.reload();
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .spinnerOverlay {
        position: absolute;
        z-index: 1000;
        width: 97.5%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0,0,0,0.1);
        backdrop-filter: blur(2px);
        border-radius: 10px;
    }
    .waitText {
        position: fixed;
        top: 40%;
        left: 50%;
        margin: 0 auto;
        margin-left: -329px;
        z-index: 10000;
        color: #fff;
        text-align: center;
        font-size: 100px;
        text-shadow: 0px 4px #000;
        .smallertext {
            font-size: 50px;
        }
    }

    .quickSpinner {
        animation: rotation 4s infinite linear;
    }
    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
    }
</style>
