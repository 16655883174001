<template>
    <form :action="'/teams/register_new_season'" method="post" @submit.prevent="onSubmit">
            <div class="step3-content row" v-show="step == 3">
                <div class="col-12">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="w-10 text-center">#</th>
                                <th class="w-30">HDSV Nr.</th>
                                <th class="w-50">Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(player, idx) in team.players" v-bind:key="player.id">
                                <td class="text-center">{{idx+1}}</td>
                                <td>{{player.passnr}}</td>
                                <td>{{player.name}}</td>
                                <td class="text-center" v-if="removable_players.includes(player.id)">
                                    <form method="post" @submit.prevent="removePlayer(player.id)">
                                        <a class="btn btn-danger" href="#" @click.prevent="removePlayer(player.id)">Entfernen</a>
                                    </form>
                                </td>
                                <td v-else></td>
                            </tr>
                            <tr id="newplayer" v-if="team.players.length < 11">
                                <td class="text-right">Suche nach HDSV Nr:</td>
                                <td><input type="text" class="form-control" placeholder="HDSV Nr." v-model="newplayernumber"></td>
                                <td><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="spinner"></span> {{newplayer ? newplayer.name : ""}}</td>
                                <td class="text-center"><a href="#" class="btn btn-success" @click.prevent="addNewPlayer">Hinzuf&uuml;gen</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-block clickLoader">Spieler nachmelden</button>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card mt-2 mb-4">
                                <div class="card-header">
                                    Spieler ohne HDSV Nummer hinzuf&uuml;gen
                                </div>
                                <div class="card-body" v-if="!synchronized">
                                    Um einen Spieler ohne HDSV Nummer hinzuzufügen bitte zunächst den Spieler über die <a href="http://www.hdsv.de/registrierung" target="_blank">HDSV Homepage</a> anmelden. Ist der Spieler dort gemeldet wird eine E-Mail versandt in der die neue HDSV Nummer mitgeteilt wird.
                                    Diese Nummer dann benutzen um den Spieler in der Ligaverwaltung zu finden. Sollte der Spieler nicht gefunden werden, bitte den Knopf "Synchronisieren" drücken. Nach erfolgreicher Synchronisation bitte nochmals suchen.
                                </div>
                                <div class="card-body" v-if="synchronized">
                                    Die Datenbank ist auf dem aktuellen Stand. Sollte kein Spieler mit der gesuchten HDSV Nummer gefunden werden, bitte an den Ligasekret&auml;r wenden.
                                </div>
                                <div class="card-footer" v-if="!synchronized">
                                    <a href="#" class="btn btn-primary btn-block clickLoader" @click.prevent="synchronizeDatabase()">Synchronisieren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="step step4 row" v-show="step == 4">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title"><h1>Spieler erfolgreich nachgemeldet.</h1></div>
                            <p>Um einen weiteren Spieler nachzumelden, rufe bitte die Nachmeldungssseite neu auf.</p>
                        </div>
                    </div>
                </div>
            </div>
    </form>
</template>

<script>
    export default {
        props: {
           team: Object,
           locations: Array,
           isnewteam: Number,
           authplayer: Object,
        },
        data() {
            return {
                next_location_id: Number,
                newplayer: {},
                newplayernumber: "",
                step: 3,
                teamErrors: [],
                spielort: {},
                synchronized: false,
                removable_players: [],
                spinner: false,
            }
        },
        created() {
            console.log(this.team)
        },
        mounted() {
        },
        watch: {
            newplayernumber: function(newVal) {
                this.searchNr(newVal)
            }
        },
        methods: {
            forceUpdate() {
                this.$forceUpdate();
            },
            goto(newstep) {
                this.step = newstep;
                this.checkValidTeam();
            },
            newteam() {
                this.createDefaultValues();
                this.goto(2);
            },
            createDefaultValues() {
                this.team.next_name = "";
                this.team.next_location_id = undefined;
                this.team.next_captain_id = undefined;
                this.team.next_cocaptain_id = undefined;
                this.team.next_players = [];
                this.team.next_notes = '';
                this.team.newteam = true;
                this.team.next_players.push(this.authplayer);
            },
            searchNr(val) {
                if (val.length >=4) {
                    this.spinner = true;
                    //suchen
                    axios.post('/players/searchObj/', {"search": val}).then(response => {
                        this.spinner = false;
                        if (response.data) {
                            if (
                                response.data[0] &&
                                (response.data[0].next_team_id == null || response.data[0].next_team_id == this.team.id) &&
                                response.data[0].passnr.replace("he", "").replace("HE", "") == val.replace("HE","")
                            )
                                this.newplayer = response.data[0];
                            else
                                this.newplayer = null;
                        } else {
                            this.newplayer = null;
                        }
                    })
                }
            },
            synchronizeDatabase() {
                 axios.post('/teams/syncexternaldatabase/')
                .then(response => {
                   this.synchronized = true;
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            },
            addNewPlayer() {
                if (this.newplayer && this.newplayer.name) {
                    var duplicate = false;
                    this.team.players.forEach(player => {
                        //don't allow duplicate entries
                        if (this.newplayer.id == player.id) {
                            duplicate = true;
                        }
                    });
                    if (!duplicate) {
                        this.team.players.push(this.newplayer);
                        this.removable_players.push(this.newplayer.id);
                        this.$forceUpdate();
                        this.newplayer = {};
                        this.newplayernumber = "";
                    }
                }
            },
            removePlayer(id) {
                var teamplayers = []
                this.team.players.forEach(player => {
                    if (this.removable_players.includes(id)) {
                        console.log(this.removable_players);
                        if (player.id != id) teamplayers.push(player);
                    } else {
                        teamplayers.push(player);
                    }
                });
                this.team.players = teamplayers;
                this.$forceUpdate();
            },
            onSubmit (e) {
                var url = '/teams/' + this.team.id + '/nachmeldung';
                axios.post(url, {
                    'team': this.team
                })
                .then(response => {
                    //this.goto(4);
                    Vue.notify({
                        group: 'foo',
                        title: 'Erfolg!',
                        text: 'Team erfolgreich gespeichert'
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        }
    }
</script>
