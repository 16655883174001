<template>
    <div class="col-12 messages-container">
        <div :class="['message', {'message-odd': message.sender_id == current}]" v-for="message in messages" v-bind:key="message.id">
            <template v-if="message.message.startsWith('T|')">
                <div class="message-content">
                    Terminvorschlag: {{dateSuggest(message.message, 'Day')}} {{dateSuggest(message.message, 'Time')}}<br>
                    <div v-if="isNotInSenderTeam(message.sender_id, current)">
                        <a href="#" class="btn btn-success" @click="acceptNewDate(message)">Terminvorschlag annehmen</a>
                        <a href="#" class="btn btn-danger" @click="declineNewDate(message)">Terminvorschlag ablehnen</a>
                    </div>
                    <div v-else>
                        <p>Warte auf Antwort der anderen Mannschaft.</p>
                    </div>
                </div>
            </template>
            <template v-else-if="message.message.startsWith('TA|')">
                <div class="message-content">
                    <div class="newDateAccepted">Terminvorschlag angenommen. Neuer Termin: {{dateSuggest(message.message, 'Day')}} um {{dateSuggest(message.message, 'Time')}} Uhr</div>
                    Am: {{dateSuggest(message.message, 'AcceptedDate')}} von {{dateSuggest(message.message, 'User')}}<br>
                </div>
            </template>
            <template v-else-if="message.message.startsWith('TD|')">
                <div class="message-content">
                    <div class="newDateDeclined">Terminvorschlag abgelehnt. {{dateSuggest(message.message, 'Day')}} um {{dateSuggest(message.message, 'Time')}} Uhr</div>
                    Am: {{dateSuggest(message.message, 'AcceptedDate')}} von {{dateSuggest(message.message, 'User')}}<br>
                </div>
            </template>
            <template v-else>
            <div class="message-content" v-html="nl2br(message.message)">
            </div>
            </template>
            <div class="message-sub">
                <span class="message-sender">{{message.sender.player ? message.sender.player.name : message.sender.name}}</span>
                <span class="message-date">{{modifyDate(message.created_at) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { setInterval } from 'timers';
export default {
    components: {
    },
    props: {
        messagegroup: Number,
        current: Number,
        username: String,
        senderteam: Array,
    },
    mounted() {
        this.getData();
        setInterval(()=> {
            this.getData();
        }, 5000)

        this.$root.$on('updateChatMessages', () => {
            this.getData();
        });
    },
    methods: {
        nl2br(str) {
            return str.replace(/(\r)*\n/g, '<br>');
        },
        modifyDate(date) {
            return moment(date).fromNow();
        },
        getData() {
            axios.get('/messages/messages/' + this.messagegroup)
                .then(response => {
                    if (response.data) {
                        this.messages = response.data;
                    }
                })
                .catch(error => {
                    // reject(error.response.data);
                })
        },
        dateSuggest(message, option) {
            var str = message.split("|");
            if (option == "Day")
                return moment(str[1]).format('dd, DD.MM.YYYY')
            if (option == "Time")
                return str[2] + ":00"
            if (option == "AcceptedDate")
                return moment(str[4]).format('DD.MM.YYYY HH:mm')
            if (option == "User")
                return str[3]

        },
        acceptNewDate(message) {
            var str = message.message.split("|");
            axios.patch("/messages/" + message.id, {
                message: 'TA|' + str[1] + "|" + str[2] + "|" + this.username + "|" + new Date(),
                newDate: str[1] + " " + str[2] + ":00:00"
            }).then(() => {
                this.$root.$emit('updateChatMessages');
            })
        },
        declineNewDate(message) {
            var str = message.message.split("|");
            axios.patch("/messages/" + message.id, {
                message: 'TD|' + str[1] + "|" + str[2] + "|" + this.username + "|" + new Date()
            }).then(() => {
                this.$root.$emit('updateChatMessages');
            })
        },
        isNotInSenderTeam(sender, current) {
            var steam = -1;
            var uteam = -1;
            this.senderteam.forEach(element => {
                if (element.user == sender) steam = element.team;
                if (element.user == current) uteam = element.team;
            });
            return steam != uteam;
        }
    },
    data() {
        return {
            messages: []
        }
    }
}
</script>

<style lang="scss" scoped>
    .message {
        margin: 0 0 1vw 0;
        .message-content {
            border: 1px solid #bbb;
            border-radius: 10px;
            padding: 1vw;
        }
        .message-sub {
            padding: 0 0 0 1vw;
            .message-sender {
                color: lighten(#333, 30%);
            }
            .message-date {
                color: lighten(#333, 50%);
            }
        }
        width: 80%;
        &.message-odd {
            margin-left: 20%;
            text-align: left;
        }
    }
    .messages-container {
        max-height: 40vh;
        overflow: auto;
    }
    .newDateAccepted {
        font-size: 1.3em;
        font-weight: bold;
        color: green;
    }
    .newDateDeclined {
        font-size: 1.1em;
        font-weight: bold;
        color: red;
    }
</style>
