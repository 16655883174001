<template>
<table class="table table-striped">
    <thead class="thead-dark">
        <tr>
            <th class="w-10">Spielername</th>
            <th class="w-10">Pass Nr.</th>
            <th class="w-20">Geburtsdatum</th>
            <th class="w-15">Teamname</th>
            <th class="w-15">Teamauswahl</th>
            <th class="w-40">Speichern</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="player in playersInTable" v-bind:key="player[0]">
            <td>{{player[7]}} {{player[8]}}</td>
            <td><input type="text" name="nummer" v-model="playersnumbers[player[0]]"></td>
            <td>{{player[9]}}</td>
            <td>{{player[5]}}</td>
            <td>
                <select name="team" v-model="playerteam[player[0]]">
                    <option v-for="team in teams" v-bind:key="team.id" :value="team.id">{{team.name}}</option>
                </select>
            </td>
            <td><input type="submit" value="Speichern" @click.prevent="onSubmit(player[0], player[7] + ' ' + player[8])"></td>        
        </tr>
    </tbody>
    <notifications group="foo" position="top center" />
    </table>
</template>

<script>
    export default {
        props: {
           players: Object,
           teams: Array,
        },
        data() {
            return {
                playersnumbers: [],
                playerteam: []
            }
        },
        created() {
            this.playersInTable = this.players;
            for (const key in this.playersInTable) {
                if (this.playersInTable.hasOwnProperty(key)) {
                    const element = this.playersInTable[key];
                    this.playersnumbers[element[0]] = element[6];    
                }
            }
        },
        methods: {
            onSubmit (id, playername) {
                axios.post("/admin/store_nachmeldung", {"id": id, "passnr":this.playersnumbers[id], "team": this.playerteam[id], "name": playername})
                .then(response => {
                    if (response.data && response.data.message == "success") {
                        //remove player from players
                        var newplayers = {};
                        for (const key in this.playersInTable) {
                            if (this.playersInTable.hasOwnProperty(key)) {
                                const element = this.playersInTable[key];
                                if (element[0] != id) newplayers[element[0]] = element;
                            }
                        }
                        this.playersInTable = newplayers;
                        this.$forceUpdate();
                    }
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: "Es ist bereits ein Spieler mit dieser Nummer registriert"
                    })
                })
            }
        }
    }
</script>
