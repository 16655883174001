<template>
    <div class="voting-message">
        <div class="card my-2">
            <div class="card-header">
                Zu dieser Nachricht gibt es eine Abstimmung:
            </div>
            <div class="card-body" v-if="!showResults && !onlyResults">
                <div class="vote" v-for="(question, qindex) in votes" v-bind:key="question.id">
                    <div class="vote-question form-group row">
                        <div class="col-10 offset-2">
                            <h4 v-html="question.question"></h4>
                        </div>
                    </div>
                    <div class="vote-answers form-group row" v-for="(answer, aindex) in question.answers" :key="answer.id">

                        <label :for="answer.id" class="col-2">Option {{aindex+1}}:</label>
                        <div class="col-10">
                            <a href="#" class="btn btn-outline-primary" @click.prevent="chooseAnswer(qindex, aindex)">
                                <i class="far fa-square" v-if="notSelected(qindex, aindex)"></i>
                                <i class="fas fa-check-square" v-if="selected(qindex, aindex)"></i>
                                <span v-html="answer"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-if="!showResults && !onlyResults">
                <a href="#" class="btn btn-success" @click.prevent="sendAnswers()">Antworten absenden</a>
                <a href="#" class="btn btn-primary float-right" @click.prevent="toggleResults()">Ergebnisse anzeigen</a>
            </div>

            <div class="card-body" v-if="showResults">
                <div class="vote" v-for="(question, qindex) in votes" v-bind:key="question.id">
                    <div class="vote-question form-group row">
                        <div class="col-12">
                            <h4 v-html="question.question" class="m-0"></h4>
                        </div>
                    </div>
                    <div class="vote-answers form-group row align-items-end" v-for="(answer, aindex) in question.answers" :key="answer.id">
                        <div class="col-10">
                            <p v-html="answer" class="m-0 p-0"></p>
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" :style="'width: '+getPercent(qindex, aindex)+'%'" :aria-valuenow="getPercent(qindex, aindex)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-2">
                            {{getPercent(qindex, aindex)}}%
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-if="showResults && !onlyResults">
                <span>{{postMessage}}</span>
                <a href="#" class="btn btn-primary float-right" @click.prevent="toggleResults()">Antwortm&ouml;glichkeiten anzeigen</a>
            </div>

            <div class="card-footer" v-if="showResults && onlyResults">
                <p class="m-0">Du bist f&uuml;r diese Abstimmung nicht abstimmungsberechtigt.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['votesprop', 'message_group_id', 'voteable'],
        data() {
            return {
                votes: this.votesprop,
                showResults: false,
                onlyResults: false,
                postMessage: "",
            }
        },
        mounted() {
            console.log(this.votes);
            if (!this.voteable) {
                this.onlyResults = true;
                this.showResults = true;
            }
        },
        methods: {
           chooseAnswer(qindex, aindex) {
               this.votes[qindex].selected_answer = aindex;
               this.$forceUpdate();
           },
           selected(qindex, aindex) {
               return this.votes[qindex].selected_answer == aindex;
           },
           notSelected(qindex,aindex) {
               return !this.selected(qindex,aindex)
           },
           toggleResults() {
               this.showResults = !this.showResults;
               this.postMessage =  "";
           },
           sendAnswers() {
               var data = {
                   'votes': [],
                   'message_group_id': this.message_group_id
               };
               this.votes.forEach(vote => {
                   data['votes'].push({
                       id: vote.id,
                       answer: vote.selected_answer
                   })
               });
               axios.post("/votes/", data).then((response) => {
                   var votesb = this.votes;
                   this.votes = response.data;
                   for (let i = 0; i < votesb.length; i++) {
                       this.votes[i].selected_answer = votesb[i].selected_answer
                   }
                   this.showResults = true;
                   this.postMessage = "Ihre Antworten wurden gespeichert."
               })
           },
           getPercent(qindex, aindex) {
               return this.votes[qindex].results.answers[aindex] ? this.votes[qindex].results.answers[aindex] : 0;
           }
        }
    }
</script>
