<template>
    <div class="voting">
        <div class="vote" v-for="(question, qindex) in voteoptions" v-bind:key="question.id">
            <div class="vote-question form-group row mt-3">
                <label :for="question.id" class="col-2 text-right">Frage:</label>
                <div class="col-8">
                    <input type="text" class="form-control" :name="question.id" v-model="question.question" placeholder="Frage hier eintragen">
                </div>
                <div class="col-2">
                    <a href="#" class="btn btn-danger btn-block" @click.prevent="removeQuestion(qindex)">L&ouml;schen</a>
                </div>
            </div>
            <div class="vote-answers form-group row" v-for="(answer, aindex) in question.answers" :key="answer.id">

                <label :for="answer.id" class="col-2 text-right">Antwort:</label>
                <div class="col-8">
                    <input type="text" class="form-control" :name="answer.id" v-model="answer.answer" placeholder="Antwortoption hier eintragen">
                </div>
                <div class="col-2">
                    <a href="#" class="btn btn-danger btn-block" @click.prevent="removeAnswer(qindex, aindex)">L&ouml;schen</a>
                </div>
            </div>
            <div class="row">
                <div class="col-8 offset-2">
                    <a href="#" class="btn btn-info" @click.prevent="addAnswer(qindex)">Antwort hinzuf&uuml;gen</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a href="#" class="btn btn-success" @click.prevent="addQuestion()">Frage hinzuf&uuml;gen</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                voteoptions: [
                    // {
                    //     id: "voteid1",
                    //     question: "Frage",
                    //     answers: [
                    //         {
                    //             id: "voteid1answer1",
                    //             answer: "Antwort1"
                    //         },
                    //         {
                    //             id: "voteid1answer2",
                    //             answer: "Antwort2"
                    //         }
                    //     ]
                    // }
                ]
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods: {
            addQuestion() {
                this.voteoptions.push({
                    id: "voteid"+(this.voteoptions.length+1),
                    question: "",
                    answers: [],
                })
            },
            removeQuestion(idx) {
                var new_array = []
                for (let index = 0; index < this.voteoptions.length; index++) {
                    const element = this.voteoptions[index];
                    if (index != idx) new_array.push(element)
                }
                this.voteoptions = new_array;
            },
            addAnswer(qidx) {
                this.voteoptions[qidx].answers.push({
                    id: "voteid"+(this.voteoptions.length)+"answer"+(this.voteoptions[qidx].answers.length+1),
                    answer: ""
                })
            },
            removeAnswer(qidx, idx) {
                var new_array = []
                for (let index = 0; index < this.voteoptions[qidx].answers.length; index++) {
                    const element = this.voteoptions[qidx].answers[index];
                    if (index != idx) new_array.push(element)
                }
                this.voteoptions[qidx].answers = new_array;
            }
        }
    }
</script>
