<template>
    <div class="leaguegeames">
        <p>Bitte Liga ausw&auml;hlen: 
            <select v-model="liga" @change="changeLeague">
                <option v-for="li in leagues" v-bind:key="li.id" :value="li">{{li.name}}</option>
            </select>
        </p>
        <div class="row" v-if="liga.teams">
            <div class="col-4">
                <h4>Aufstellung</h4>
                <div v-for="index in planSize" :key="index">
                    {{index}}: <select v-model="teams[index-1]">
                        <option v-for="team in liga.teams" :key="team.id" :value="team">{{team.name}}</option>
                        <option :value="noteam">{{noteam.name}}</option>
                    </select>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <a href="#" @click.prevent="saveGames" class="btn btn-primary btn-block mt-2 clickLoader">Spiele speichern</a>
            </div>
            <div class="col-8">
                <h4>Spielplan</h4>
                <table class="table table-striped table-sm">
                    <tbody v-for="(day, indexDay) in plan" :key="indexDay">
                    <tr>
                        <th colspan="2" class="text-center">Spieltag {{(indexDay+1)}}</th>
                    </tr>

                    <tr v-for="(match, indexMatch) in day" :key="indexMatch">
                        <td><span v-if="teams[match[0]-1]">{{teams[match[0]-1].name}}</span></td>
                        <td><span v-if="teams[match[1]-1]">{{teams[match[1]-1].name}}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            args: Object
        },
        created() {
            this.matchdays = this.args.matchdaysImport;
            this.leagues = this.args.leaguesImport;
        },
        methods: {
            changeLeague() {
                if (this.liga.teams.length <= 6) {
                    this.planSize = 6;
                    this.plan = this.planWithSixTeams;
                    this.teams = JSON.parse(JSON.stringify(this.liga.teams));
                }
                else {
                    this.planSize = 8;
                    this.plan = this.planWithEightTeams;
                    this.teams = JSON.parse(JSON.stringify(this.liga.teams));
                }
            },
            saveGames() {
                var data = {games: [], league: this.liga.id, leaguesize: this.planSize}
                var dayIndex = 1;
                this.plan.forEach(day => {
                    day.forEach(match => {
                        data.games.push([dayIndex, this.teams[match[0]-1].id, this.teams[match[1]-1].id])
                    });
                    dayIndex += 1;
                });
                
                axios.post("/admin/storeleaguematches", data)
                .then(response => {
                    Vue.notify({
                        group: 'foo',
                        title: 'Erfolg!',
                        text: "Spiele erfolgreich angelegt"
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        },
        data() {
            return {
                leagues: [],
                matchdays: [],
                teams: [],
                liga: {},
                planSize: 6,
                noteam: {name: "Spielfrei"},
                plan: undefined,
                planWithSixTeams: [
                    [[1,2], [5,3], [6,4]],
                    [[3,1], [4,5], [6,2]],
                    [[2,3], [1,4], [5,6]],
                    [[4,2], [5,1], [6,3]],
                    [[3,4], [2,5], [1,6]],

                    [[2,1], [3,5], [4,6]],
                    [[1,3], [5,4], [2,6]],
                    [[3,2], [4,1], [6,5]],
                    [[2,4], [1,5], [3,6]],
                    [[4,3], [5,2], [6,1]],
                ],
                planWithEightTeams: [
                    [[1,2], [6,4], [7,3], [8,5]],
                    [[4,5], [3,6], [2,7], [1,8]],
                    [[3,1], [4,7], [6,5], [8,2]],
                    [[2,3], [1,4], [5,7], [6,8]],
                    [[5,3], [6,2], [7,1], [8,4]],
                    [[4,2], [5,1], [6,7], [3,8]],
                    [[3,4], [2,5], [1,6], [7,8]],

                    [[2,1], [4,6], [3,7], [5,8]],
                    [[5,4], [6,3], [7,2], [8,1]],
                    [[1,3], [7,4], [5,6], [2,8]],
                    [[3,2], [4,1], [7,5], [8,6]],
                    [[3,5], [2,6], [1,7], [4,8]],
                    [[2,4], [1,5], [7,6], [8,3]],
                    [[4,3], [5,2], [6,1], [8,7]],
                ]
            }
            
        }
    }
</script>