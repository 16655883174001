<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/locations/',
                method: 'post',
                fields: [
                    {type: 'text', name: 'name', label: 'Name des Spielortes'},
                    [
                        {type: 'text', name: 'strasse', label: 'Straße'},
                        {type: 'text', name: 'hausnummer', label: 'Hausnummer'},
                    ],
                    [
                        {type: 'text', name: 'plz', label: 'PLZ'},
                        {type: 'text', name: 'ort', label: 'Ort'},
                    ],
                    {type: 'text', name: 'telefon', label: 'Telefon'},
                    {type: 'select', name: 'raucher', label: 'Raucher', options: [
                        {name:"auch während des Ligaspiels wird geraucht", id:"auch während des Ligaspiels wird geraucht"},
                        {name:"eine absolut rauchfreie Spielstätte", id:"eine absolut rauchfreie Spielstätte"},
                        {name:"Keine Angabe", id:"Keine Angabe"},
                    ]},
                    {type: 'select', name: 'gcheck', label: 'Impfstatus', options: [
                        {name:"3G", id:"3G"},
                        {name:"2G", id:"2G"},
                        {name:"2G+", id:"2G+"},
                        {name:"Keine Angabe", id:""},
                    ]},
                    [
                        {type: 'text', name: 'geocoord1', label: 'Geo Koordinaten (Lat) *optional'},
                        {type: 'text', name: 'geocoord2', label: 'Geo Koordinaten (Lon) *optional'},
                    ],
                    {type: 'submit', name: 'Hinzufügen'}
                ]
            }

        }
    }
</script>
