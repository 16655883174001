<template>
    <div class="row">
        <div class="col-12 row">
            <div class="col-12"><h3>Average Anpassung</h3></div>
            <div class="col-4">
                A <input type="text" v-model="averageA">
            </div>
            <div class="col-4">
                B <input type="text" v-model="averageB">
            </div>
            <div class="col-4">
                C <input type="text" v-model="averageC">
            </div>
        </div>
        <div class="col-4">
            <section class="list">
                <header>Dillenburg</header>
                <div v-for="(league, index2) in leaguesDillenburg" :key="index2">
                    <h3>
                        {{league}} ({{teamsDillenburg[league].length}}) 
                        <a href="#" class="d-inline-block float-right" @click.prevent="toggleVisibility('dil',league)">
                            <i :class="['fas', 'fa-toggle-on', 'toggle-dil-'+league]"></i>
                        </a>
                        <a href="#" class="btn btn-outline-info mr-1" @click.prevent="sortTeams('dil', league)"><i class="fas fa-sort"></i></a>
                    </h3>
                    <draggable :class="['drag-area','league-dil-'+league]" :list="teamsDillenburg[league]" :animation="200" :group="'status'" :tag="'article'" @add="onAdd($event, false)"  @change="update">
                        <article class="card" v-for="(team, index) in teamsDillenburg[league]" :key="index" :data-id="team.id">
                            <header class="row">
                                <div class="col-6 text-left">
                                    {{ team.next_name }}
                                </div>
                                <div class="col-6 text-right">
                                    {{ teamaverage(team)}}
                                </div>
                            </header>
                            <div class="row">
                                <div class="col-6 text-left">
                                    {{ team.lastleague}}
                                </div>
                                <div class="col-6 text-right">
                                    {{ team.ort}}
                                </div>
                            </div>
                        </article>
                    </draggable>
                </div>
            </section>
        </div>
        <div class="col-4">
            <section class="list">
                <header>Teams <a href="#" class="btn btn-outline-info" @click.prevent="sortTeams()"><i class="fas fa-sort"></i></a></header>
                <draggable class="drag-area"  :list="teamsAll" :animation="200" :group="'status'" :tag="'article'" @add="onAdd($event, true)"  @change="update">
                    <article :class="['card', regionColor(team.lastleague)]" v-for="(team, index) in teamsAll" :key="index" :data-id="team.id">
                       <header class="row">
                                <div class="col-6 text-left">
                                    {{ team.next_name }}
                                </div>
                                <div class="col-6 text-right">
                                    {{ teamaverage(team)}}
                                </div>
                            </header>
                            <div class="row">
                                <div class="col-6 text-left">
                                    {{ team.lastleague}}
                                </div>
                                <div class="col-6 text-right">
                                    {{ team.ort}}
                                </div>
                            </div>
                    </article>
                </draggable>  
            </section>
        </div>
        <div class="col-4">   
            <section class="list">
                <header>Wetzlar</header>
                <div v-for="(league, index2) in leaguesWetzlar" :key="index2">
                     <h3>
                        {{league}} ({{teamsWetzlar[league].length}}) 
                        <a href="#" class="d-inline-block float-right" @click.prevent="toggleVisibility('wz', league)">
                            <i :class="['fas', 'fa-toggle-on', 'toggle-wz-'+league]"></i>
                        </a>
                        <a href="#" class="btn btn-outline-info mr-1" @click.prevent="sortTeams('wz', league)"><i class="fas fa-sort"></i></a>
                    </h3>
                    <draggable :class="['drag-area','league-wz-'+league]" :list="teamsWetzlar[league]" :animation="200" :group="'status'" :tag="'article'" @add="onAdd($event, true)"  @change="update">
                        <article class="card" v-for="(team, index) in teamsWetzlar[league]" :key="index" :data-id="team.id">
                           <header class="row">
                                <div class="col-6 text-left">
                                    {{ team.next_name }}
                                </div>
                                <div class="col-6 text-right">
                                    {{ teamaverage(team)}}
                                </div>
                            </header>
                            <div class="row">
                                <div class="col-6 text-left">
                                    {{ team.lastleague}}
                                </div>
                                <div class="col-6 text-right">
                                    {{ team.ort}}
                                </div>
                            </div>
                        </article>
                    </draggable>  
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'

    export default {
        components: {
            draggable
        },
        props: ['teams'],
        data() {
            return {
                teamsAll: this.teams,
                teamsDillenburg: {"BZO": [],"BZ": [],"A": [],"B1": [],"B2": [], "C1": [],"C2": [],"C3": [],},
                leaguesDillenburg: ["BZO", "BZ", "A", "B1", "B2", "C1", "C2", "C3"],
                teamsWetzlar: {"BZ": [],"A": [],"B1": [],"B2": [], "C1": [],"C2": [],"C3": [],},
                leaguesWetzlar: ["BZ", "A", "B1", "B2", "C1", "C2", "C3"],
                averageA: 1,
                averageB: 0.9,
                averageC: 0.8,
            }
        },
        mounted() {
            this.sortTeams();
        },
        methods: {
            onAdd(event, status) {
                let id = event.item.getAttribute('data-id');
                // axios.patch('/demos/tasks/' + id, {
                //     status: status
                // }).then((response) => {
                //     console.log(response.data);
                // }).catch((error) => {
                //     console.log(error);
                // })
            },
            update() {
                // this.teamsDillenburg.map((task, index) => {
                //     task.order = index + 1;
                // });

                // this.tasksCompletedNew.map((task, index) => {
                //     task.order = index + 1;
                // });

                // let tasks = this.teamsDillenburg.concat(this.tasksCompletedNew);

                // axios.put('/demos/tasks/updateAll', {
                //     tasks: tasks
                // }).then((response) => {
                //     console.log(response.data);
                // }).catch((error) => {
                //     console.log(error);
                // })
            },
            teamaverage(team) {
                let modifier = 1;
                if (!team.lastleague) return team.average.toFixed(2);
                if (team.lastleague.indexOf(" A") != -1) modifier = this.averageA;
                if (team.lastleague.indexOf(" B1") != -1 || (team.lastleague.indexOf(" B2") != -1)) modifier = this.averageB;
                if (team.lastleague.indexOf(" C") != -1) modifier = this.averageC;
                let teamaverage = team.average * parseFloat(modifier);
                return teamaverage.toFixed(2);
            },
            toggleVisibility(column, league) {
                $(".toggle-" + column + "-" + league).removeClass("fa-toggle-on");
                $(".toggle-" + column + "-"  + league).removeClass("fa-toggle-off");
                $(".league-" + column + "-"  + league).toggleClass("d-none");
                if ($(".league-" + column + "-"  + league).hasClass("d-none")) {
                    $(".toggle-" + column + "-"  + league).addClass("fa-toggle-off");
                } else {
                    $(".toggle-" + column + "-"  + league).addClass("fa-toggle-on");
                }
            },
            sortTeams(region = '', league = '') {
                var self = this;
                if (region == '') {
                    this.teamsAll.sort(function(a,b) {
                        return (self.teamaverage(a) - self.teamaverage(b));
                    })
                    this.teamsAll.reverse();
                } else {
                    if (region == 'dil') {
                        this.teamsDillenburg[league].sort(function(a,b) {
                            return (self.teamaverage(a) - self.teamaverage(b));
                        });
                        this.teamsDillenburg[league].reverse();
                    } else {
                        this.teamsWetzlar[league].sort(function(a,b) {
                            return (self.teamaverage(a) - self.teamaverage(b));
                        });
                        this.teamsWetzlar[league].reverse();
                    }
                }
                
            },
            regionColor(league) {
                if (!league) return '';
                if (league.indexOf("Dbg") != -1) return "regionColorDbg";
                else if (league.indexOf("Wz") != -1) return "regionColorWz";
                else return "regionColorBzo";
            }
        }
    }
</script>

<style lang="scss">
    .list {
      background-color: #e2f0fb;
      border-radius: 3px;
      margin: 5px 5px;
      padding: 10px;
      width: 100%;
    }
    .list>header {
      font-weight: bold;
      color: #385d7a;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      cursor: grab;
    }
    .list article {
      border-radius: 3px;
      margin-top: 10px;
    }

    .list .card {
      background-color: #FFF;
      border-bottom: 1px solid #CCC;
      padding: 15px 10px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bolder;
      &.regionColorDbg {
          background-color: #EEEEFF;
      }
      &.regionColorWz {
          background-color: #EEFFEE;
      }
      &.regionColorBzo {
          background-color: #FFEEEE;
      }
    }
    .list .card:hover {
      background-color: #F0F0F0;
    }
    .drag-area{
     min-height: 10px;  
    }
</style>