<template>
    <div class="notes">
        <label for="notes">Bemerkungen</label>
        <textarea name="notes" class="form-control" id="notes" cols="30" rows="5" v-model="match.notes"></textarea>
        <a href="#" class="btn btn-primary btn-block" @click.prevent="onSubmit()" v-if="!match.report_checked">Bemerkungen Speichern</a>
    </div>
</template>

<script>
    export default {
        props: {
            match: Object,
        },
        data() {
            return {
                url: '/matches/'+this.match.id
            }
        },
        methods: {
            onSubmit () {
                var data = {
                    'notes': this.match.notes,
                    '_method': 'PATCH'
                }
                axios.post(this.url, data)
                .then(response => {
                    this.$parent.callSiteReload();
                    location.reload();
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error
                    })
                })
            }
        }
    }
</script>