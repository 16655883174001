<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/locations/' + this.args.location.id,
                method: 'patch',
                fields: [
                    {type: 'text', name: 'name', label: 'Name des Spielortes', value: this.args.location.name},
                    [
                        {type: 'text', name: 'strasse', label: 'Straße', value: this.args.location.strasse},
                        {type: 'text', name: 'hausnummer', label: 'Hausnummer', value: this.args.location.hausnummer},
                    ],
                    [
                        {type: 'text', name: 'plz', label: 'PLZ', value: this.args.location.plz},
                        {type: 'text', name: 'ort', label: 'Ort', value: this.args.location.ort},
                    ],
                    {type: 'text', name: 'telefon', label: 'Telefon', value: this.args.location.telefon},
                    {type: 'select', name: 'raucher', label: 'Raucher', value: this.args.location.raucher, options: [
                        {name:"auch während des Ligaspiels wird geraucht", id:"auch während des Ligaspiels wird geraucht"},
                        {name:"eine absolut rauchfreie Spielstätte", id:"eine absolut rauchfreie Spielstätte"},
                        {name:"Keine Angabe", id:"Keine Angabe"},
                    ]},
                    {type: 'select', name: 'gcheck', label: 'Impfstatus', value: this.args.location.gcheck, options: [
                        {name:"3G", id:"3G"},
                        {name:"2G", id:"2G"},
                        {name:"2G+", id:"2G+"},
                        {name:"Keine Angabe", id:""},
                    ]},
                    [
                        {type: 'text', name: 'geocoord1', label: 'Geo Koordinaten (Lat) *optional', value: this.args.location.geocoord1},
                        {type: 'text', name: 'geocoord2', label: 'Geo Koordinaten (Lon) *optional', value: this.args.location.geocoord2},
                    ],
                    {type: 'submit', name: 'Speichern'}
                ]
            }

        }
    }
</script>
