<template>
    <form-builder url="/teams" method="post" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        data() {
            return {
                fields: [
                    {type: 'text', name: 'name', label: 'Team Name'},
                    {type: 'submit', name: 'Team erstellen'}
                ]
            }
            
        }
    }
</script>