<template>
    <form-builder :url="url" :method="method" :fields="fields" :argsdata="args.league"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/leagues/' + this.args.league.id,
                method: 'patch',
                fields: [
                    {type: 'text', name: 'name', label: 'Name der Liga', value: this.args.league.name},
                    {type: 'select', name: 'season_id', label: 'Saison', value: this.args.league.season_id, options: this.args.seasons},
                    {type: 'select', name: 'ligatyp', label: 'Ligatyp', value: this.args.league.ligatyp, options: [
                        {id:"Bezirksoberliga", name: "Bezirksoberliga"},
                        {id:"Bezirksliga", name: "Bezirksliga"},
                        {id:"A-Liga", name: "A-Liga"},
                        {id:"B-Liga", name: "B-Liga"},
                        {id:"C-Liga", name: "C-Liga"}
                    ]},
                    {type: 'divider', name: 'Liga Optionen'},
                    [
                        {type: 'inputnumber', name: 'gamecount_single', label: 'Einzelspiele'},
                        {type: 'checkbox', name: 'single_with_legs', label: 'Legs zählen (Einzel)'},
                        {type: 'leaguespecial', name: 'single_process', label: 'Ablauf der Einzelspiele'},
                        {type: 'inputnumber', name: 'gamecount_double', label: 'Doppelspiele'},
                        {type: 'checkbox', name: 'double_with_legs', label: 'Legs zählen (Doppel)'},
                        {type: 'leaguespecial2', name: 'double_process', label: 'Ablauf der Doppelspiele'},
                    ],
                    [
                        {type: 'inputnumber', name: 'game_legs', label: 'Legs zum Gewinn'},
                        {type: 'inputnumber', name: 'game_maxrounds', label: 'Max. Anzahl Runden pro Spiel'},
                    ],
                    [
                        {type: 'inputnumber', name: 'points_win', label: 'Punkte fuer Sieg'},
                        {type: 'inputnumber', name: 'points_draw', label: 'Punkte fuer Unentschieden'},
                        {type: 'inputnumber', name: 'points_loss', label: 'Punkte fuer Niederlage'},
                    ],
                    [
                        {type: 'inputnumber', name: 'players_max', label: 'Max. Anzahl Spieler pro Team'},
                        {type: 'inputnumber', name: 'players_max_game', label: 'Max. Anzahl Spieler pro Spiel'},
                        {type: 'inputnumber', name: 'players_max_subs', label: 'Max. Anzahl Auswechslungen'},
                    ],
                    {type: 'select', name:'league_admin_id', label: 'Liga Admin', value: this.args.league.league_admin_id, options: this.args.admin_options},
                    {type: 'submit', name: 'Speichern'}
                ]
            }
            
        }
    }
</script>