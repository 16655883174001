<template>
  <div>
    <b-table striped small :items="tabledata" :fields="fields" head-variant="dark">
        <template slot="playerlink" slot-scope="data">
        <a :href="`/players/${data.item.id}`">{{ data.item.name }}</a>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    props: {
        tabledata: Array,
        fields: Array,
    },
    created() {
    },
    data() {
      return {}
    },
    methods: {
    }
  }
</script>