<template>
    <form :action="'/teams/register_new_season'" method="post" @submit.prevent="onSubmit">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-2 mb-4">
                        <div class="card-body">
                            Sollten beim Anmelden des Teams technische Probleme auftreten, bitte eine Mail an <a href="mailto:ligaverwaltung@hdsv.de">ligaverwaltung@hdsv.de</a> schicken oder das Fehler Melden Formular benutzen.
                        </div>
                    </div>
                </div>
            </div>
            <div class="step step1 row">
                <div class="col-12">
                    <span class="label">1</span>
                    <span class="text">Teamstatus eingeben</span>
                </div>
            </div>
            <div class="step1-content row" v-show="step == 1">
                <div class="col-6" v-if="isnewteam == 0 && !team.newteam">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Team bleibt bestehen</h3>
                        </div>
                        <div class="card-body">
                            <p>Möchtest du dein Team für die nächste Saison wieder melden, so ist dies die richtige Option.</p>
                            <ul>
                                <li>Teamname bleibt gleich</li>
                                <li>Statistiken werden fortgefuehrt</li>
                                <li>Teammitglieder k&ouml;nnen ausgetauscht werden</li>
                                <li>Spielort kann ausgetauscht werden</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-primary btn-block" @click.prevent="goto(2)">Bestehendes Team &uuml;bernehmen</a>
                        </div>
                    </div>
                </div>
                <div class="col-6" v-if="isnewteam == 0">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Neues Team anlegen</h3>
                        </div>
                        <div class="card-body">
                            <p>Wähle diese Option, wenn du ein komplett neues Team melden willst.</p>
                            <ul>
                                <li>Neuer Teamname</li>
                                <li>Statistiken werden nicht fortgefuehrt</li>
                                <li>Teammitglieder werden neu hinzugef&uuml;gt</li>
                                <li>Spielort wird neu angelegt oder bestehender Ort wird ausgew&auml;hlt</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-primary btn-block" @click.prevent="newteam()">Neues Team anlegen</a>
                        </div>
                    </div>
                </div>
                <div class="col-6" v-if="isnewteam == 1">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Neues Team bearbeiten</h3>
                        </div>
                        <div class="card-body">
                            <p>Du hast f&uuml;r die nächste Saison ein neues Team gemeldet.</p>
                            <ul>
                                <li>Neuer Teamname</li>
                                <li>Statistiken werden nicht fortgefuehrt</li>
                                <li>Teammitglieder werden neu hinzugef&uuml;gt</li>
                                <li>Spielort wird neu angelegt oder bestehender Ort wird ausgew&auml;hlt</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-primary btn-block" @click.prevent="goto(2)">Neues Team bearbeiten</a>
                        </div>
                    </div>
                </div>
                 <div class="col-6" v-if="isnewteam == 1">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Neues Team l&ouml;schen</h3>
                        </div>
                        <div class="card-body">
                            <p>Du hast f&uuml;r die nächste Saison ein neues Team gemeldet.</p>
                            <ul>
                                <li>Neuer Teamname</li>
                                <li>Statistiken werden nicht fortgefuehrt</li>
                                <li>Teammitglieder werden neu hinzugef&uuml;gt</li>
                                <li>Spielort wird neu angelegt oder bestehender Ort wird ausgew&auml;hlt</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-danger btn-block" @click.prevent="deletenewteam()">Neues Team l&ouml;schen</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="step step2 row">
                <div class="col-12">
                    <span class="label">2</span>
                    <span class="text">Teamname und Spielort</span>
                </div>
            </div>
            <div class="step2-content row" v-show="step == 2">
                <div class="col-6">
                    <label for="name">Teamname</label>
                    <input type="text" class="form-control" v-model="team.next_name">
                </div>
                <div class="col-6">
                    <label for="location">Spielort</label>
                    <select v-model="team.next_location_id" class="form-control">
                        <option :value="opt.id" v-for="opt in locations" v-bind:key="opt.id" class="form-control">{{opt.name}} in {{opt.ort}}</option>
                    </select>
                    <a href="#" data-toggle="modal" data-target="#newLocationModal">Neuen Spielort anlegen</a>
                </div>
                <div class="col-12">
                    <label for="next_notes">Anmerkungen (zum Beispiel W&uuml;nsche und Anforderungen bez&uuml;glich Spielort und Liga)</label>
                    <textarea v-model="team.next_notes" class="form-control" cols="30" rows="10"></textarea>
                </div>
                <div class="col-12 mt-2">
                    <a href="#" class="btn btn-primary" @click.prevent="goto(1)">Zur&uuml;ck</a>
                    <a href="#" class="btn btn-primary" @click.prevent="goto(3)">Weiter</a>
                </div>
                <div class="modal fade" id="newLocationModal" tabindex="-1" role="dialog" aria-labelledby="newLocationModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="newLocationModalLabel">Neuen Spielort anlegen</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="spielort.name">Name des Spielortes</label>
                                            <input type="text" class="form-control" v-model="spielort.name">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="spielort.strasse">Stra&szlig;e</label>
                                            <input type="text" class="form-control" v-model="spielort.strasse">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="spielort.hausnummer">Hausnummer</label>
                                            <input type="text" class="form-control" v-model="spielort.hausnummer">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="spielort.plz">PLZ</label>
                                            <input type="text" class="form-control" v-model="spielort.plz">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="spielort.ort">Ort</label>
                                            <input type="text" class="form-control" v-model="spielort.ort">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="spielort.telefon">Telefon</label>
                                            <input type="text" class="form-control" v-model="spielort.telefon">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="spielort.Raucher">Raucher</label>
                                            <select v-model="spielort.raucher" class="form-control">
                                                <option value="auch während des Ligaspiels wird geraucht">auch während des Ligaspiels wird geraucht</option>
                                                <option value="eine absolut rauchfreie Spielstätte">eine absolut rauchfreie Spielstätte</option>
                                                <option value="Keine Angabe">Keine Angabe</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
                                <button type="button" class="btn btn-success" @click="saveNewLocation()">Spielort speichern</button>
                            </div>
                            </div>
                        </div>
                </div>
            </div>

            <div class="step step3 row">
                <div class="col-12">
                    <span class="label">3</span>
                    <span class="text">Teammitglieder</span>
                </div>
            </div>

            <div class="step3-content row" v-show="step == 3">
                <div class="col-12">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="w-10 text-center">#</th>
                                <th class="w-10">HDSV Nr.</th>
                                <th class="w-30">Name</th>
                                <th class="w-15 text-center">Captain</th>
                                <th class="w-15 text-center">Co-Captain</th>
                                <th class="w-20 text-center">Aktion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(player, idx) in team.next_players" v-bind:key="player.id">
                                <td class="text-center">{{idx+1}}</td>
                                <td>{{player.passnr}}</td>
                                <td>{{player.name}}</td>
                                <td><input type="radio" class="form-control" v-model="team.next_captain_id" name="captain" id="captainradio" :value="player.id" :disabled="player.id == team.next_cocaptain_id" @click="updateRadioButtons()"></td>
                                <td><input type="radio" class="form-control"  v-model="team.next_cocaptain_id" name="cocaptain" id="cocaptainradio" :value="player.id" :disabled="player.id == team.next_captain_id" @click="updateRadioButtons()"></td>
                                <td class="text-center">
                                    <form method="post" @submit.prevent="add(player)">
                                        <a class="btn btn-danger" href="#" @click.prevent="removePlayer(player.id)">Entfernen</a>
                                    </form>
                                </td>
                            </tr>
                            <tr id="newplayer" v-if="team.next_players.length < 10">
                                <td class="text-right">Suche:</td>
                                <td><input type="text" class="form-control" placeholder="HDSV Nr." v-model="newplayernumber"></td>
                                <td>{{newplayer ? newplayer.name : ""}} {{newplayerNotAvailable ? newplayerNotAvailable : "" }}</td>
                                <td></td>
                                <td></td>
                                <td class="text-center"><a href="#" class="btn btn-success" @click.prevent="addNewPlayer">Hinzuf&uuml;gen</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-12">
                            <div class="card mt-2 mb-4">
                                <div class="card-header">
                                    Spieler ohne HDSV Nummer hinzuf&uuml;gen
                                </div>
                                <div class="card-body" v-if="!synchronized">
                                    Um einen Spieler ohne HDSV Nummer hinzuzufügen bitte zunächst den Spieler über die <a href="http://www.hdsv.de/registrierung" target="_blank">HDSV Homepage</a> anmelden. Ist der Spieler dort gemeldet wird eine E-Mail versandt in der die neue HDSV Nummer mitgeteilt wird.
                                    Diese Nummer dann benutzen um den Spieler in der Ligaverwaltung zu finden. Sollte der Spieler nicht gefunden werden, bitte den Knopf "Synchronisieren" drücken. Nach erfolgreicher Synchronisation bitte nochmals suchen.
                                </div>
                                <div class="card-body" v-if="synchronized">
                                    Die Datenbank ist auf dem aktuellen Stand. Sollte kein Spieler mit der gesuchten HDSV Nummer gefunden werden, bitte an den Ligasekret&auml;r wenden.
                                </div>
                                <div class="card-footer" v-if="!synchronized">
                                    <a href="#" class="btn btn-primary btn-block clickLoader" @click.prevent="synchronizeDatabase()">Synchronisieren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <a href="#" class="btn btn-primary" @click.prevent="goto(2)">Zur&uuml;ck</a>&nbsp;
                    <button type="submit" class="btn btn-primary clickLoader" :disabled="teamErrors.length > 0">Team Registrierung abschliessen</button>
                    <br>
                    <br>
                    <div class="alert alert-danger" v-if="teamErrors.length > 0">
                        <ul>
                        <li v-for="error in teamErrors" v-bind:key="error">{{error}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="step step4 row" v-show="step == 4">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title"><h1>Team Registrierung erfolgreich</h1></div>
                            <p>Du kannst dein Team weiter bearbeiten, indem du nochmals auf die Mannschaftsanmeldung klickst. Deine bisher eingegebenen Daten werden &uuml;bernommen.</p>
                        </div>
                    </div>
                </div>
            </div>
    </form>
</template>

<script>
    export default {
        props: {
           team: Object,
           locations: Array,
           isnewteam: Number,
           authplayer: Object,
        },
        data() {
            return {
                next_location_id: Number,
                newplayer: {},
                newplayerNotAvailable: null,
                newplayernumber: "",
                step: 1,
                teamErrors: [],
                spielort: {},
                synchronized: false,
            }
        },
        created() {
            if (!this.team) {
                this.team = {};
                this.createDefaultValues();
            } else {
                this.team.next_location_id = this.team.next_location_id ? this.team.next_location_id : this.team.location.id;
                this.team.next_name = this.team.next_name ? this.team.next_name : this.team.name;
                this.team.next_captain_id = this.team.next_captain_id ? this.team.next_captain_id : this.team.captain_id;
                this.team.next_cocaptain_id = this.team.next_cocaptain_id ? this.team.next_cocaptain_id : this.team.cocaptain_id;
                if (this.team.next_players.length == 0) {
                    this.team.players.forEach(p => {
                        if (p.next_team_id == null || p.next_team_id == this.team.id) {
                            this.team.next_players.push(p);
                        }
                    });
                }
                this.team.next_notes = this.team.next_notes ? this.team.next_notes : "";
                this.team.newteam = false;
            }
            this.team.matches = null;
            this.team.matches_home = null;
            this.team.matches_away = null;
            this.team.matchesFinished = null;
            console.log(this.team);

        },
        mounted() {
        },
        watch: {
            newplayernumber: function(newVal) {
                this.searchNr(newVal)
            }
        },
        methods: {
            forceUpdate() {
                this.$forceUpdate();
            },
            goto(newstep) {
                this.step = newstep;
                this.checkValidTeam();
            },
            newteam() {
                this.createDefaultValues();
                this.goto(2);
            },
            createDefaultValues() {
                this.team.next_name = "";
                this.team.next_location_id = undefined;
                this.team.next_captain_id = undefined;
                this.team.next_cocaptain_id = undefined;
                this.team.next_players = [];
                this.team.next_notes = '';
                this.team.newteam = true;
                this.team.next_players.push(this.authplayer);
            },
            deletenewteam() {
                 axios.post('/teams/delete_new_team/')
                .then(response => {
                    location.reload();
                    Vue.notify({
                        group: 'foo',
                        title: 'Erfolg!',
                        text: 'Team erfolgreich gespeichert'
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            },
            searchNr (val) {
                val = val.replace("HE", "");
                val = val.replace("he", "");
                if (val.length >=4) {
                    //suchen
                    this.newplayerNotAvailable = null;
                    axios.post('/players/searchObj/', {"search": val}).then(response => {
                        if (response.data) {
                            if (
                                response.data[0] &&
                                (response.data[0].next_team_id == null || response.data[0].next_team_id == this.team.id) &&
                                response.data[0].passnr.replace("he", "").replace("HE", "") == val.replace("HE","")
                            )
                                this.newplayer = response.data[0];
                            else {
                                    this.newplayer = null;
                                    this.newplayerNotAvailable = response.data[0].name + " ist bereits in einem Team gemeldet";
                                }
                        } else {
                            this.newplayer = null;
                        }
                    })
                }
            },
            synchronizeDatabase() {
                 axios.post('/teams/syncexternaldatabase/')
                .then(response => {
                   this.synchronized = true;
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            },
            addNewPlayer() {
                if (this.newplayer && this.newplayer.name) {
                    var duplicate = false;
                    this.team.next_players.forEach(player => {
                        //don't allow duplicate entries
                        if (this.newplayer.id == player.id) {
                            duplicate = true;
                        }
                    });
                    if (!duplicate) {
                        this.team.next_players.push(this.newplayer);
                        this.$forceUpdate();
                        this.newplayer = {};
                        this.newplayernumber = "";
                    }
                }
                this.checkValidTeam();
            },
            removePlayer(id) {
                var teamplayers = []
                this.team.next_players.forEach(player => {
                    if (player.id != id) teamplayers.push(player);
                });
                if (id == this.team.next_captain_id) this.team.next_captain_id = null;
                if (id == this.team.next_cocaptain_id) this.team.next_cocaptain_id = null;
                this.team.next_players = teamplayers;
                this.checkValidTeam();
                this.$forceUpdate();
            },
            updateRadioButtons() {
                setTimeout(() => {
                    this.$forceUpdate();
                    this.checkValidTeam();
                }, 0);
            },
            checkValidTeam() {
                if (this.teamErrors.length > 0) this.teamErrors = [];
                if (this.team.next_players.length < 4)   { this.teamErrors.push("Achtung, zu wenig Spieler im Team.")}
                if (this.team.next_captain_id == null)   { this.teamErrors.push("Achtung, kein Captain angegeben.")}
                if (this.team.next_cocaptain_id == null) { this.teamErrors.push("Achtung, kein Co-Captain angegeben.")}
                if (this.team.next_location_id == null)  { this.teamErrors.push("Achtung, kein Spielort angegeben.")}
                if (this.team.next_name.length < 1)      { this.teamErrors.push("Achtung, kein Teamname angegeben.")}
            },
            saveNewLocation() {
                if (!this.spielort.name) return;
                if (!this.spielort.hausnummer) return;
                if (!this.spielort.strasse) return;
                if (!this.spielort.plz) return;
                if (!this.spielort.raucher) return;
                if (!this.spielort.ort) return;
                this.spielort.override = true;
                axios.post('/locations/', this.spielort)
                .then(response => {
                    //Update All Locations and set location to new location
                    $("#newLocationModal").modal('hide');
                    this.locations = response.data.locations;
                    this.team.next_location_id = this.locations[this.locations.length-1].id;
                    this.goto(2);
                    Vue.notify({
                        group: 'foo',
                        title: 'Erfolg!',
                        text: 'Spielort erfolgreich angelegt'
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            },
            onSubmit (e) {
                var url = '';
                if (this.team.id) url = '/teams/store_new_season/' + this.team.id;
                else url = '/teams/store_new_season/';
                axios.post(url, {
                    'team': this.team
                })
                .then(response => {
                    this.goto(4);
                    $("#alert-new-season-team").html("Mannschaft f&uuml;r n&auml;chste Saison bearbeiten");
                    Vue.notify({
                        group: 'foo',
                        title: 'Erfolg!',
                        text: 'Team erfolgreich gespeichert'
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        }
    }
</script>
