<template>
    <div class="highscores">
        <hr class="divider">
        <h5>Highscores</h5>
        <table class="table table-striped">
            <thead class="table-dark">
                <tr>
                    <th class="w-40">
                        Spieler
                    </th>
                    <th class="w-10 text-right">
                        180
                    </th>
                    <th class="w-10 text-right">
                        171
                    </th>
                    <th class="w-20 text-right">
                        Highfinish
                    </th>
                    <th class="w-20 text-center">
                        Entfernen
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="highscore in match.highscores" v-bind:key="highscore.id">
                    <td>
                        {{highscore.player.name}}
                    </td>
                    <td class="w-10 text-right">
                        {{highscore.s180}}
                    </td>
                    <td class="w-10 text-right">
                        {{highscore.s171}}
                    </td>
                    <td class="w-20 text-right">
                        {{highscore.highfinish}}
                    </td>
                    <td>
                        <a href="#" class="btn btn-danger btn-block" @click.prevent="onDelete(highscore.id)" v-if="!match.report_checked">Entfernen</a>
                    </td>
                </tr>
            </tbody>
            <tr v-if="!match.report_checked">
                <td>
                    <div class="form-group">
                        <select class="form-control" name="player" id="player" v-model="fplayer">
                            <option v-for="(option, index) in players" v-bind:key="index+' '+option.id" :value="option.id">
                                {{option.name}}
                            </option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <input @click="selectField" type="number" name="f180" placeholder="0" class="form-control text-right" v-model="f180">
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <input @click="selectField" type="number" name="f171" placeholder="0" class="form-control text-right" v-model="f171">
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <input @click="selectField" type="number" name="highfinish" placeholder="0" class="form-control text-right" v-model="highfinish">
                    </div>
                </td>
                <td class="text-center">
                    <a href="#" class="btn btn-primary btn-block" @click.prevent="onSubmit()">Highscore eintragen</a>
                </td>
            </tr>
        </table>
    </div>
    
</template>

<script>
    export default {
        props: {
            players: Array,
            match: Object,
        },
        data() {
            return {
                url: '/highscores',
                fplayer: undefined,
                f180: 0,
                f171: 0,
                highfinish: undefined
            }
        },
        methods: {
            selectField() {
                document.execCommand("selectall",null,false);
            },  
            onSubmit () {
                var data = {
                    'player_id': this.fplayer,
                    'match_id': this.match['id'],
                    's180': this.f180,
                    's171': this.f171,
                    'highfinish': this.highfinish
                }
                axios.post(this.url, data)
                .then(response => {
                    this.$parent.callSiteReload();
                    location.reload();
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error
                    })
                })
            },
            onDelete(id) {
                axios.delete("/highscores/"+id)
                .then(response => {
                    location.reload();
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error
                    })
                })
            }
        }
    }
</script>
