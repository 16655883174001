<template>
    <div class="form-group">
        <div v-if="field.type == 'text'">
            <label for="name">{{field.label}}</label>
            <input type="text" :name="field.name" class="form-control" v-model="form[field.name]">
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>

        <div v-if="field.type == 'password'">
            <label for="name">{{field.label}}</label>
            <input type="password" :name="field.name" class="form-control" v-model="form[field.name]">
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>

        <div v-if="field.type == 'textarea'">
            <label for="name">{{field.label}}</label>
            <textarea :name="field.name" class="form-control" v-model="form[field.name]" cols="30" rows="5"></textarea>
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>

        <div v-if="field.type == 'inputnumber'">
            <label for="name">{{field.label}}</label>
            <input type="number" :name="field.name" class="form-control" v-model="form[field.name]">
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>

        <div v-if="field.type == 'select'">
            <div class="form-group">
              <label :for="field.name">{{field.label}}</label>
              <select class="form-control" :name="field.name" :id="field.name" v-model="form[field.name]" @change="field.callback ? field.callback() : ''">
                <option value="" v-if="!field.noblank"></option>
                <option v-for="option in field.options" v-bind:key="option.id" :value="option.id" :selected="option.id == field.value">
                    {{option.name}}
                </option>
              </select>
            </div>
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>
        <div v-if="field.type == 'datetime'">
            <div class="form-group">
                <label :for="field.name">{{field.label}}</label>
                <div class="input-group date" :id="field.name+'_id'" data-target-input="nearest">
                    <input type="text" :name="field.name" class="form-control datetimepicker-input" :data-target="'#'+field.name+'_id'" data-toggle="datetimepicker"/>
                    <div class="input-group-append" :data-target="'#'+field.name+'_id'" data-toggle="datetimepicker">
                        <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="field.type == 'submit'">
            <label for="test">&nbsp;</label><br>
            <button type="submit" class="btn btn-primary clickLoader" :disabled="form.errors.any()">
                <div ref="spinner" class="spinner-border spinner-border-sm d-none" role="status"></div>
                {{field.name}}
            </button>
        </div>

        <div v-if="field.type == 'divider'">
            <div class="row mt-4">
                <div class="col-12">
                <h4>{{field.name}}</h4>
                <hr class="divider m-0">
                </div>
            </div>
        </div>

        <div v-if="field.type == 'checkbox'">
            <label for="name">{{field.label}}</label>
            <input type="checkbox" :name="field.name" class="form-control" v-model="form[field.name]">
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>

        <div v-if="field.type == 'toggle'">
            <label for="name">{{field.label}}</label><br>
            <input type="checkbox" :name="field.name" class="form-control" v-model="form[field.name]">
            <span v-text="form.errors.get(field.name)" v-if="form.errors.has(field.name)" class="text-danger"></span>
        </div>

        <div v-if="field.type == 'description'">
            <p>{{field.value}}</p>
        </div>

        <div v-if="field.type == 'hidden'">
            <input type="hidden" :name="field.name" class="form-control" v-model="form[field.name]">
        </div>

        <div v-if="field.type == 'leaguespecial'">
            <label for="name">{{field.label}}</label>
            <a href="#" data-toggle="modal" :data-target="'#modal'+field.name">Spielplanung aufrufen</a>
            <input type="hidden" :name="field.name" v-model="form[field.name]">

            <div class="modal fade" :id="'modal'+field.name" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Spielplanung</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Bitte stellen Sie ein, wie die {{field.label.split(" ")[(field.label.split(" ").length-1)]}} ausgespielt werden sollen.
                        <div class="row" v-for="i in (field.label.indexOf('Einzel') != -1 ? form['gamecount_single'] : form['gamecount_double'])" v-bind:key="'game-'+i">
                            <div class="col-4">
                                Spiel Nr. {{i}}
                            </div>
                            <div class="col-4">
                                <select class="form-control" :name="'home-'+i" v-model="form['games']['home'+i]">
                                    <option v-for="option in 10" v-bind:key="'option-'+option" :value="option">
                                        H{{option}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-4">
                                <select class="form-control" :name="'home-'+i" v-model="form['games']['away'+i]">
                                    <option v-for="option in 10" v-bind:key="'option-'+option" :value="option">
                                        A{{option}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
                        <button type="submit" class="btn btn-primary" data-dismiss="modal" @click.prevent="handleLaguespecial(field.label.indexOf('Einzel') != -1 ?'single' : 'double')">Speichern</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="field.type == 'leaguespecial2'">
            <label for="name">{{field.label}}</label>
            <a href="#" data-toggle="modal" :data-target="'#modal'+field.name">Spielplanung aufrufen</a>
            <input type="hidden" :name="field.name" v-model="form[field.name]">

            <div class="modal fade" :id="'modal'+field.name" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Spielplanung</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Bitte stellen Sie ein, wie die Doppelspiele ausgespielt werden sollen.
                        <div class="row" v-for="i in form['gamecount_double']" v-bind:key="'game-'+i">
                            <div class="col-4">
                                Spiel Nr. {{i}}
                            </div>
                            <div class="col-4">
                                <select class="form-control" :name="'home-'+i" v-model="form['gamesdouble']['home'+i]">
                                    <option v-for="option in 10" v-bind:key="'option-'+option" :value="option">
                                        H{{option}}
                                    </option>
                                </select>
                                <select class="form-control" :name="'home-'+i" v-model="form['gamesdouble']['homes'+i]">
                                    <option v-for="option in 10" v-bind:key="'option-'+option" :value="option">
                                        H{{option}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-4">
                                <select class="form-control" :name="'home-'+i" v-model="form['gamesdouble']['away'+i]">
                                    <option v-for="option in 10" v-bind:key="'option-'+option" :value="option">
                                        A{{option}}
                                    </option>
                                </select>
                                <select class="form-control" :name="'home-'+i" v-model="form['gamesdouble']['aways'+i]">
                                    <option v-for="option in 10" v-bind:key="'option-'+option" :value="option">
                                        A{{option}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
                        <button type="submit" class="btn btn-primary" data-dismiss="modal" @click.prevent="handleLaguespecial('double')">Speichern</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
       props: {
            field: Object,
            form: Object
        },
        created() {
            if (this.field.type == 'leaguespecial') {
                this.parseProccessSingle();
            }
            if (this.field.type == 'leaguespecial2') {
                this.parseProccessDouble();
            }
        },
        mounted() {
            if (this.field.type == 'datetime' && $('#'+this.field.name+'_id').datetimepicker) {
                if (!this.form[this.field.name]) {
                    var d = new Date();
                    d.setHours(19);
                    d.setMinutes(0);
                    this.form[this.field.name] = moment(d).format('YYYY-MM-DD HH:mm:ss')
                    $('#'+this.field.name+'_id').datetimepicker(
                        {
                        locale: 'de',
                        defaultDate: d
                        },

                    );
                } else {
                    this.form[this.field.name] = moment(this.form[this.field.name]).format('YYYY-MM-DD HH:mm:ss')
                    $('#'+this.field.name+'_id').datetimepicker(
                    {
                    locale: 'de',
                    defaultDate: this.form[this.field.name]
                    },
                );
                }
                $('#'+this.field.name+'_id').on('change.datetimepicker', (e)=> {
                    this.form[this.field.name] = moment(e.date).format('YYYY-MM-DD HH:mm:ss');
                })
            }
            if (this.field.type == 'toggle') {
                jQuery(this.$el).find(".form-control").bootstrapToggle({
                    on: 'Ja',
                    off: 'Nein'
                });
                jQuery(this.$el).find(".form-control").change((evt)=> {
                    this.form[this.field.name] = jQuery(this.$el).find(".form-control").prop('checked') ? 1 : 0;
                });
            }
        },
        methods: {
            parseProccessSingle() {
                this.form['games'] = {};
                var single_process = JSON.parse(this.form['single_process']);
                if (single_process)
                for (let i = 1; i <= this.form['gamecount_single']; i++) {
                    if (single_process[i-1]) this.form['games']['home'+i] = single_process[i-1][0];
                    if (single_process[i-1]) this.form['games']['away'+i] = single_process[i-1][1];
                }
            },
            parseProccessDouble() {
                this.form['gamesdouble'] = {};
                var process = JSON.parse(this.form['double_process']);
                if (process)
                for (let i = 1; i <= this.form['gamecount_double']; i++) {
                    if (process[i-1]) this.form['gamesdouble']['home'+i] = process[i-1][0];
                    if (process[i-1]) this.form['gamesdouble']['homes'+i] = process[i-1][2];
                    if (process[i-1]) this.form['gamesdouble']['away'+i] = process[i-1][1];
                    if (process[i-1]) this.form['gamesdouble']['aways'+i] = process[i-1][3];
                }
            },
            handleLaguespecial(typ) {
                var gameorder = [];
                if (typ == "single") {
                    for (let i = 1; i <= this.form['gamecount_single']; i++) {
                        if (this.form['games']['home'+i]) {
                            if (!gameorder[i-1]) gameorder[i-1] = [];
                            gameorder[i-1][0] = this.form['games']['home'+i];
                        }
                        if (this.form['games']['away'+i]) {
                            if (!gameorder[i-1]) gameorder[i-1] = [];
                            gameorder[i-1][1] = this.form['games']['away'+i];
                        }
                    }
                    this.form['single_process'] = JSON.stringify(gameorder);
                    $('input[name=single_process]').val(JSON.stringify(gameorder));
                } else if (typ == "double") {
                    for (let i = 1; i <= this.form['gamecount_double']; i++) {
                        if (this.form['gamesdouble']['home'+i]) {
                            if (!gameorder[i-1]) gameorder[i-1] = [];
                            gameorder[i-1][0] = this.form['gamesdouble']['home'+i];
                        }
                        if (this.form['gamesdouble']['away'+i]) {
                            if (!gameorder[i-1]) gameorder[i-1] = [];
                            gameorder[i-1][1] = this.form['gamesdouble']['away'+i];
                        }
                        if (this.form['gamesdouble']['homes'+i]) {
                            if (!gameorder[i-1]) gameorder[i-1] = [];
                            gameorder[i-1][2] = this.form['gamesdouble']['homes'+i];
                        }
                        if (this.form['gamesdouble']['aways'+i]) {
                            if (!gameorder[i-1]) gameorder[i-1] = [];
                            gameorder[i-1][3] = this.form['gamesdouble']['aways'+i];
                        }
                    }
                    this.form['double_process'] = JSON.stringify(gameorder);
                    $('input[name=double_process]').val(JSON.stringify(gameorder));
                }
            }
        }
    }
</script>
