<template>
    <div>
        <div class="form-inline mb-2">
            <label for="token" class="mr-4">Suche mittels Token:</label>
            <input class="form-control" type="text" v-model="token" id="token" @keyup="search()">
        </div>
        <div class="ergebnisse">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="w-20">Pass Nr.</th>
                        <th class="w-60">Name</th>
                        <th class="w-20 text-center">Verbinden</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="player in players" v-bind:key="player.id">
                    <td>{{player.passnr}}</td>
                    <td>{{player.name}}</td>
                    <td class="text-center">
                        <form method="post" @submit.prevent="add(player)">
                            <button type="submit" class="btn btn-primary">Verbinden</button>
                        </form>
                    </td>
                </tr>
                <tr v-if="players.length == 0">
                    <td colspan="3" class="text-center">Bitte nach Token suchen oder keine Ergebnisse gefunden.</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                token: '',
                players: []
            }
        },
        props: {
            user: Object
        },
        methods: {
            search() {
                axios.get("/users/searchplayer?token="+this.token)
                    .then(this.onSuccess)
            },
            onSuccess(response) {
                this.players = response.data.players;
            },
            add(player) {
                var data = {};
                data.name = this.user.name;
                data.email = this.user.email;
                data.player_id = player.id;
                data.token = this.token;
                data._method = 'patch';
                axios.post('/users/'+this.user.id, data)
                    .then(this.onSuccessAdd)
            },
            onSuccessAdd() {
                window.location.reload();
            }
        }

    }
</script>
