<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/leagues/',
                method: 'post',
                fields: [
                    {type: 'text', name: 'name', label: 'Name der Liga'},
                    {type: 'select', name: 'season_id', label: 'Saison', options: this.args.seasons, noblank: true, value:this.args.seasons[0].id},
                    {type: 'select', name: 'ligatyp', label: 'Ligatyp', options: [
                        {id:"Bezirksoberliga", name: "Bezirksoberliga"},
                        {id:"Bezirksliga", name: "Bezirksliga"},
                        {id:"A-Liga", name: "A-Liga"},
                        {id:"B-Liga", name: "B-Liga"},
                        {id:"C-Liga", name: "C-Liga"}
                    ]},
                    {type: 'divider', name: 'Liga Optionen'},
                    [
                        {type: 'inputnumber', name: 'gamecount_single', label: 'Einzelspiele', value:16},
                        {type: 'inputnumber', name: 'gamecount_double', label: 'Doppelspiele', value:2},
                        
                    ],
                    [
                        {type: 'inputnumber', name: 'game_legs', label: 'Legs zum Gewinn', value:2},
                        {type: 'inputnumber', name: 'game_maxrounds', label: 'Max. Anzahl Runden pro Spiel', value:99},
                    ],
                    [
                        {type: 'inputnumber', name: 'points_win', label: 'Punkte fuer Sieg', value:2},
                        {type: 'inputnumber', name: 'points_draw', label: 'Punkte fuer Unentschieden', value:1},
                        {type: 'inputnumber', name: 'points_loss', label: 'Punkte fuer Niederlage', value:0},
                    ],
                    [
                        {type: 'inputnumber', name: 'players_max', label: 'Max. Anzahl Spieler pro Team', value:100},
                        {type: 'inputnumber', name: 'players_max_game', label: 'Max. Anzahl Spieler pro Spiel', value:100},
                        {type: 'inputnumber', name: 'players_max_subs', label: 'Max. Anzahl Auswechslungen', value:100},
                    ],
                    {type: 'submit', name: 'Hinzufügen'}
                ]
            }
            
        }
    }
</script>