<template>
    <form-builder url="/matchdays" method="post" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        data() {
            return {
                fields: [
                    {type: 'datetime', name: 'matchday', label: 'Spieltag'},
                    {type: 'checkbox', name: 'extratermin', label: 'Zusatz Spieltag'},
                    {type: 'submit', name: 'Spieltag erstellen'}
                ]
            }
            
        }
    }
</script>