import Errors from './formErrors.js';
export default class Form {
    constructor(data) {
        this.spinner = null;
        this.originalData = data;
        for (const field in data) {
            this[field] = data[field];
        }
        this.errors = new Errors();
    }
    reset() {
         for (let field in this.originalData) {
            this[field] = '';
        }
        this.errors.clear();
    }
    data() {
        let data = {}
        for (const property in this.originalData) {
            data[property] = this[property];
        }
        return data;
    }
    updateData(data) {
        this.originalData = data;
        for (const field in data) {
            this[field] = data[field];
        }
    }
    getValue(field) {
        return this[field] ? this[field] : undefined;
    }
    submit(requestType, url) {
        var data = this.data();
        if (requestType == 'patch') {
            requestType = 'post';
            data['_method'] = 'patch';
        }
        this.toggleSpinner();
        return new Promise((resolve, reject) => {
            axios[requestType](url, data)
                .then(response => {
                    this.onSuccess(response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data);
                    reject(error.response.data);
                })
        });            
    }
    onSuccess(data) {
        this.toggleSpinner();
        //this.reset();
        Vue.notify({
            group: 'foo',
            title: 'Erfolg!',
            text: data.message
        })
    }
    onFail(errors) {
        this.toggleSpinner();
        this.errors.record(errors);
        Vue.notify({
            group: 'foo',
            type: 'error',
            title: 'Ein Fehler ist aufgetreten',
            text: 'Bitte die Eingaben überprüfen.'
        })
    }
    toggleSpinner() {
        if (!this.spinner) return;
        this.spinner.classList.toggle("d-none");
    }
}