<template>
    <div class="card">
        <div class="card-header">Terminvorschlag</div>

        <div class="card-body">
            <div class="grid">
                <div class="left">
                    <h3>Innerhalb von 4 Wochen</h3>
                    <p>Du kannst einen Termin innerhalb von 4 Wochen des Ursprungstermins vorschlagen. Die andere Mannschaft kann diesen Termin dann annehmen oder ablehnen.</p>
                    <select name="terminvorschlag" id="terminvorschlag" v-model="terminvorschlag">
                        <option :value="d.value" v-for="d in possibleDates" :key="d.value" :class="[d.isSaturday ? 'saturday' : '']" :selected="d.selected">{{d.name}}</option>
                    </select>
                    <select name="terminvorschlag2" id="terminvorschlag" v-model="terminvorschlag2">
                        <option :value="d.value" v-for="d in startTimes" :key="d.value" :class="[d.isPrime ? 'saturday' : '']" :selected="d.isPrime">{{d.name}}</option>
                    </select>
                    <input class="btn btn-primary" type="submit" value="Termin vorschlagen" @click="suggestDate()">
                </div>
                <div class="right">
                    <h3>Anderer Termin</h3>
                    <p>Falls der Termin au&szlig;erhalb von 4 Wochen nach dem Spieltag liegt, muss der Ligasekret&auml;r zustimmen. Klicke hier, um den Ligasekret&auml;r in die Terminbesprechung mit einzubeziehen.</p>
                    <p><a href="#" class="btn btn-danger" @click="addAdminToDiscussion()">Terminbesprechung mit Ligasekret&auml;r</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["match", "messagegroup"],
        data() {
            return {
                startDate: this.match.matchdate,
                possibleDates: [],
                startTimes: [],
                terminvorschlag: '',
                terminvorschlag2: '19',
            }
        },
        mounted() {
            this.createDates();
            for (let i = 0; i < 15; i++) {
                this.startTimes.push({
                    'value': 8+i,
                    'name': (8+i)+":00",
                    'isPrime': i == 11
                });
            }
        },
        methods: {
            createDates() {
                for (let i = moment().diff(moment(this.startDate), 'days'); i <0 ; i++) {
                    this.possibleDates.push({
                        'value': moment(this.startDate).add(i, 'days').format('YYYY-MM-DD'),
                        'name': moment(this.startDate).add(i, 'days').format('dd, DD.MM.YYYY'),
                        'isSaturday': moment(this.startDate).add(i, 'days').weekday() == 5,
                        'selected': i == 0
                    });
                }
                for (let i = 0; i <= 31; i++) {
                    var date = new Date(this.startDate);
                    this.possibleDates.push({
                        'value': moment(this.startDate).add(i, 'days').format('YYYY-MM-DD'),
                        'name': moment(this.startDate).add(i, 'days').format('dd, DD.MM.YYYY'),
                        'isSaturday': moment(this.startDate).add(i, 'days').weekday() == 5,
                        'selected': i == 0
                    });
                }
            },
            suggestDate() {
                if (this.terminvorschlag != '') {
                    axios.post("/messages", {
                        message_group_id: this.messagegroup,
                        message: 'T|' + this.terminvorschlag + "|" + this.terminvorschlag2
                    }).then(() => {
                        this.$root.$emit('updateChatMessages');
                    })
                }
            },
            addAdminToDiscussion() {
                axios.post("/messages/addAdmin", {
                    message_group_id: this.messagegroup,
                    message: 'Ligasekretär wurde der Terminbesprechung hinzugefügt'
                }).then(() => {
                    this.$root.$emit('updateChatMessages');
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    option {
        color: rgba(0,0,0,0.6);
    }
    option.saturday {
        font-weight: bold;
        color: rgba(0,0,0,1);
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
</style>
