<template>
    <bar-chart :chart-data="datacollection" :options="options" :height="200"></bar-chart>
</template>

<script>
  import BarChart from '../barchart.js'

  export default {
    components: {
      BarChart
    },
    props: {
        highscores: Object,
    },
    data () {
      return {
        datacollection: null,
        options: {
          aspectRatio: 5,
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                  beginAtZero: true,
                  stepSize: 1,
              }
            }]
          }
        }
      }
    },
    created () {
        this.highscoresRender = this.highscores['finishes'];
        this.fillData();
    },
    methods: {
      fillData () {
        this.datacollection = {
          labels: this.createLabels(),
          datasets: [
            {
              label: 'Anzahl Highscores',
              backgroundColor: '#6574cd',
              borderColor: '#6574cd',
              borderWidth: 3,
              pointRadius: 6,
              data: this.createData(),
              fill: false,
              lineTension: 0,
            }
          ]
        }
      },
      createLabels() {
          var returnArray = [];
          for (const key in this.highscoresRender) {
              if (this.highscoresRender.hasOwnProperty(key)) {
                  const element = this.highscoresRender[key];
                  returnArray.push(key);
              }
          }
          return returnArray;
      },
      createData() {
          var returnArray = [];
          for (const key in this.highscoresRender) {
              if (this.highscoresRender.hasOwnProperty(key)) {
                  const element = this.highscoresRender[key];
                  returnArray.push(element);
              }
          }
          return returnArray;
      }
    }
  }
</script>

<style>
</style>