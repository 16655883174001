<template>
    <span>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="spinner"></span>
        <input type="text" :placeholder="placeholder" v-model="searchstring" @keyup.prevent="onSubmit" class="searchform-input">
    </span>
</template>

<script>
    export default {
        props: {
            url: String,
            container: String,
            placeholder: String
        },
        data() {
            return {
                searchstring: "",
                spinner: false,
            }
        },
        methods: {
            onSubmit () {
                //if (this.searchstring.length == 0) location.reload();
                if (this.searchstring.length < 3) return;
                this.spinner = true;
                axios.post(this.url, {"search": this.searchstring})
                .then(response => {
                    $(this.container).html(response.data);
                    $("ul.pagination").hide();
                    this.spinner = false;
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                    this.spinner = false;
                })
            }
        }
    }
</script>
