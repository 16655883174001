<template>
    <form-builder :url="url" :method="method" :fields="fields" :argsdata="args.user"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        created() {
        },
        methods: {
        },
        data() {
            return {
                url: '/bugs/answer/',
                method: 'post',
                fields: [
                    {type: 'hidden', name: 'bug_id', value: this.args.bug.id},
                    {type: 'textarea', name: 'description', label: ''},
                    {type: 'submit', name: 'Hinzufügen'}
                ]
            }
            
        }
    }
</script>