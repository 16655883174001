<template>
    <line-chart :chart-data="datacollection" :options="options" :height="300"></line-chart>
</template>

<script>
  import LineChart from '../linechart.js'

  export default {
    components: {
      LineChart
    },
    props: {
        datapoints: Array,
    },
    data () {
      return {
        datacollection: null,
        options: {
          aspectRatio: 5,
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                  beginAtZero: true,
                  steps: this.findMax(),
                  stepSize: 1,
                  max: this.findMax()
              }
            }]
          }
        }
      }
    },
    created () {
      this.fillData();
    },
    methods: {
      fillData () {
        this.datacollection = {
          labels: this.createLabels(),
          datasets: [
            {
              label: 'Heim',
              backgroundColor: '#6574cd',
              borderColor: '#6574cd',
              borderWidth: 3,
              pointRadius: 6,
              data: this.createData(0),
              fill: false,
              lineTension: 0,
            },
            {
              label: 'Gast',
              backgroundColor: 'rgba(255,0,0,0.4)',
              borderColor: 'rgba(255,0,0,0.4)',
              borderWidth: 3,
              pointRadius: 6,
              data: this.createData(1),
              fill: false,
              lineTension: 0,
            }
          ]
        }
      },
      createLabels() {
          var arr = []
          for (let i = 0; i < this.datapoints.length; i++) {
              arr.push((i));
              
          }
          return arr;
      },
      findMax() {
          var last = this.datapoints[this.datapoints.length-1];
          return last[0] > last[1] ? last[0] : last[1];
      },
      createData(num) {
          var arr = [];
          this.datapoints.forEach(p => {
              arr.push(p[num]);
          });
          return arr;
      },
    }
  }
</script>

<style>
    canvas {
        max-width: 100%;
    }
</style>