<template>
    <input type="checkbox" 
        :name="args.permission.name"
        v-model="value"
    >
</template>

<script>
    export default {
        props: {
            args: Object
        },
        data() {
            return {
                value: this.args.active
            }
        },
        computed: {
            $$el() {
            return jQuery(this.$el)
            }
        },
        mounted() {
            this.$$el.bootstrapToggle({
                on: 'Ja',
                off: 'Nein'
            });

            this.$$el.change((evt)=> {
                this.onSubmit(this.$$el.prop('checked'));
            })
        },
        methods: {
            onSubmit (checked) {
                var data = {
                    permission: this.args.permission.name,
                    value: checked ? 1 : 0,
                    _method: 'patch'
                }
                axios.post("/roles/" + this.args.role.id, data)
                .then(response => {
                    Vue.notify({
                    group: 'foo',
                        title: 'Erfolg!',
                        text: data.message
                    })
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error.response.data
                    })
                })
            }
        }
    }
</script>
