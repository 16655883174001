<template>
    <form-builder :url="url" :method="method" :fields="fields" :argsdata="args.user"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        created() {
        },
        methods: {
        },
        data() {
            return {
                url: '/users/' + this.args.user.id,
                method: 'patch',
                fields: [
                    {type: 'text', name: 'name', label: 'Name'},
                    {type: 'text', name: 'email', label: 'E-Mail Adresse'},
                    {type: 'text', name: 'telefon', label: 'Telefon (kann nur von Spielern aus der eigenen Liga eingesehen werden)'},
                    {type: 'toggle', name: 'emailmessages', label: 'Nachrichten an meine E-Mail Adresse weiterleiten'},
                    {type: 'description', value: "Achtung, wenn du Captain bist wirst du auch über Spielverlegungen über die Nachrichten informiert. Wenn du die E-Mail Benachrichtigung ausschaltest wirst du nicht extra über einen Spielverlegungswunsch informiert."},
                    {type: 'password', name: 'password', label: 'Passwort (wird geaendert wenn ausgefuellt)', value: ''},
                    {type: 'submit', name: 'Speichern'}
                ]
            }
            
        }
    }
</script>