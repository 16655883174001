<template>
	<div class="teamoverview-container">
		<div class="navigation">
			<a
				href="#"
				:class="['btn btn-primary p-2 m-2', show == 'currentSeason' ? 'active' : '']"
				@click.prevent="showSeason('currentSeason')"
				>Aktuelle Saison ({{ teamsprop.length }})</a
			>
			<a
				href="#"
				:class="['btn btn-primary p-2 m-2', show == 'nextSeason' ? 'active' : '']"
				@click.prevent="showSeason('nextSeason')"
				>N&auml;chste Saison ({{ nextteamsprop.length }})</a
			>
			<a
				href="#"
				:class="['btn btn-primary p-2 m-2', show == 'nextSeasonTeams' ? 'active' : '']"
				@click.prevent="showSeason('nextSeasonTeams')"
				>Teams kopieren</a
			>
			<a href="#" :class="['btn btn-info p-2 m-2']" @click.prevent="prepareTeams()">Update</a>
			<a href="#" :class="['btn btn-info p-2 m-2']" @click.prevent="toggleNotes()">Zeige / Verstecke Anmerkungen</a>
			<a href="#" :class="['btn btn-info p-2 m-2']" @click.prevent="toggleActions()">Zeige / Verstecke Aktionen</a>
		</div>
		<div class="teamoverview">
			<template v-if="show == 'currentSeason'">
				<div class="liga" v-for="league in leagues" :key="league.id">
					<h3>{{ league.name }} ({{ league.teams.length }})</h3>
					<div :class="['team', teamStatus(team)]" v-for="team in league.teams" :key="team.id">
						<div class="tablenumber">{{ team.position }}</div>
						<div class="name">
							<a :href="'/teams/' + team.id" class="text-dark"> {{ team.name }}</a>
						</div>
						<div class="playercount" v-if="!showNotes">{{ team.playercount }} Spieler</div>
						<div class="playercount" v-else>{{ team.notes }}</div>
						<div class="location">{{ team.location ? team.location.name : "" }}</div>
						<div class="location-ort">{{ team.location ? team.location.ort : "" }}</div>
					</div>
				</div>
			</template>

			<template v-if="show == 'nextSeason'">
				<div class="liga" v-for="league in nextleague" :key="league.id">
					<h3>{{ league.name }} {{ league.location }} ({{ league.teams.length }})</h3>
					<div :class="['team', teamStatus(team)]" v-for="team in league.teams" :key="team.id">
						<div class="tablenumber">{{ team.baseScore }}</div>
						<div class="name">
							<a :href="'/teams/' + team.id + '/next'" class="text-dark">
								{{ team.name }}
							</a>
						</div>
						<div class="playercount" v-if="!showNotes && !showActions">{{ team.next_playercount }} Spieler</div>
						<div class="playercount wrap" v-else-if="showNotes">{{ team.next_notes }}</div>
						<div class="location" v-if="!showActions">{{ team.location ? team.location.name : "" }}</div>
						<div class="location-ort">{{ team.location ? team.location.ort : "" }}</div>
						<div class="actions" v-if="showActions">
							<div class="setHome" @click.prevent="setHome(team, 'Dbg')">Dbg</div>
							<div class="setHome" @click.prevent="setHome(team, 'Wz')">Wz</div>
							<div class="setLeague" @click.prevent="setLeague(team, 975)">BZO</div>
							<div class="setLeague" @click.prevent="setLeague(team, 875)">BZ</div>
							<div class="setLeague" @click.prevent="setLeague(team, 725)">A</div>
							<div class="setLeague" @click.prevent="setLeague(team, 675)">B</div>
							<div class="setLeague" @click.prevent="setLeague(team, 575)">C</div>
							<!-- <div class="removeTeam" @click.prevent="removeTeam(team)" v-if="team.newteam">Team entfernen</div> -->
						</div>
						<!-- <div class="location-ort">{{team.league_id}} {{team.next_league_id}} | {{team.position}}</div> -->
					</div>
				</div>

				<div class="liga">
					<h3>Neue Teams</h3>
					<div :class="['team']" v-for="team in newteams" :key="team.id">
						<div class="name">
							<a :href="'/teams/' + team.id + '/next'" class="text-dark"> {{ team.next_name }} </a>
						</div>
						<div class="playercount" v-if="!showNotes && !showActions">{{ team.next_playercount }} Spieler</div>
						<div class="playercount wrap" v-else-if="showNotes">{{ team.next_notes }}</div>
						<div class="location">{{ team.next_location ? team.next_location.name : "" }}</div>
						<div class="location-ort">{{ team.next_location ? team.next_location.ort : "" }}</div>
						<div class="actions" v-if="showActions">
							<div class="setHome" @click.prevent="setHome(team, 'Dbg')">Dbg</div>
							<div class="setHome" @click.prevent="setHome(team, 'Wz')">Wz</div>
							<div class="setLeague" @click.prevent="setLeague(team, 975)">BZO</div>
							<div class="setLeague" @click.prevent="setLeague(team, 875)">BZ</div>
							<div class="setLeague" @click.prevent="setLeague(team, 725)">A</div>
							<div class="setLeague" @click.prevent="setLeague(team, 675)">B</div>
							<div class="setLeague" @click.prevent="setLeague(team, 575)">C</div>
						</div>
					</div>
				</div>
			</template>

			<template v-if="show == 'nextSeasonTeams'">
				<h3>Teams kopieren</h3>
				<textarea
					name="teams"
					id="teams"
					cols="30"
					rows="40"
					style="width: 100%; max-height: 50vh;"
					v-model="teamPrintable"
				></textarea>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: ["teamsprop", "leaguesprop", "nextteamsprop", "newteamsprop"],
	data() {
		return {
			show: "nextSeason",
			teams: this.teamsprop,
			nextteams: this.nextteamsprop,
			leagues: this.leaguesprop,
			newteams: this.newteamsprop,
			nextleague: null,
			teamslist: [],
			teamPrintable: "Test",
			showNotes: false,
			showActions: false,
		};
	},
	mounted() {
		this.createTeamsList();
		this.prepareTeams();
	},
	methods: {
		showSeason(season) {
			this.show = season;
			if (season == "nextSeasonTeams") {
				this.teamPrintable = "";
				var teamArray = [];
				this.leagues.forEach((league) => {
					league.teams.forEach((team) => {
						if (team.next_name.length > 1) teamArray.push(team.next_name);
					});
				});
				this.newteams.forEach((team) => {
					if (team.next_name.length > 1) teamArray.push(team.next_name);
				});
				teamArray.sort();
				teamArray.forEach((team) => {
					this.teamPrintable += team + "\n";
				});
			}
		},

		teamStatus(team) {
			let teamstatus = "";
			if (team.status) {
				if (team.status.indexOf("aufsteiger") != -1) teamstatus += " aufsteiger";
				if (team.status.indexOf("absteiger") != -1) teamstatus += " absteiger";
				if (team.status.indexOf("withdrawn") != -1) teamstatus += " withdrawn";
				if (team.status.indexOf("retired") != -1) teamstatus += " retired";
			}
			return teamstatus;
		},

		createTeamsList() {
			this.teamslist = [];
			this.teams.forEach((team) => {
				team.status = [];
				if (team.next_name == "") team.status.push("retired");
				if (team.withdraw != "") team.status.push("withdrawn");
				this.leagues.forEach((league) => {
					var i = 0;
					league.table.forEach((t) => {
						i += 1;
						if (t.team.id == team.id) {
							team.position = i;
							team.leaguename = league.name;
							team.league_id = league.id;
							team.location = t.team.location;
							if (i == 1 && league.ligatyp != "Bezirksoberliga") team.status.push("aufsteiger");
							// if (i == 5 && league.ligatyp == "Bezirksoberliga") team.status.push("absteiger");
							if (i == 6 && league.ligatyp != "C-Liga") team.status.push("absteiger");

							if (team.status.indexOf("retired") != -1) team.next_league_id = null;

							// set next league
							if (team.leaguename.indexOf("Bezirksoberliga") != -1) team.home = "Dbg";
							if (team.leaguename.indexOf("Dbg") != -1) team.home = "Dbg";
							if (team.leaguename.indexOf("Wz") != -1) team.home = "Wz";

							if (league.ligatyp == "Bezirksoberliga") team.baseScore = 1000;
							if (league.ligatyp == "Bezirksliga") team.baseScore = 900;
							if (league.ligatyp == "A-Liga") team.baseScore = 800;
							if (league.ligatyp == "B-Liga") team.baseScore = 700;
							if (league.ligatyp == "C-Liga") team.baseScore = 600;

							if (team.status.indexOf("aufsteiger") != -1) {
								// team.next_league_id = league.id -1;
								team.baseScore += 60;
							} else if (team.status.indexOf("absteiger") != -1) {
								team.baseScore -= 60;
							} else {
								// team.next_league_id = league.id;
							}
							team.baseScore -= team.position;
							// if (team.league_id == 24 && team.status.indexOf('aufsteiger') != -1) team.next_league_id = 17;
							if (team.status.indexOf("retired") == -1) {
								this.teamslist.push(team);
							}
						}
					});
				});
			});
		},
		prepareTeams() {
			this.teamslist.sort((a, b) => (a.baseScore < b.baseScore ? 1 : -1));
			let nextleagues = [
				{ name: "Bezirksoberliga", teams: [] },
				{ name: "Bezirksliga", location: "Dbg", teams: [] },
				{ name: "A-Liga", location: "Dbg", teams: [] },
				{ name: "B-Liga", location: "Dbg", teams: [] },
				{ name: "C-Liga", location: "Dbg", teams: [] },
				{ name: "Bezirksliga", location: "Wz", teams: [] },
				{ name: "A-Liga", location: "Wz", teams: [] },
				{ name: "B-Liga", location: "Wz", teams: [] },
				{ name: "C-Liga", location: "Wz", teams: [] },
			];
			this.teamslist.forEach((t) => {
				console.log(t.name + " " + t.home + " " + t.baseScore);
			});
			for (let i = 0; i < this.teamslist.length; i++) {
				const team = this.teamslist[i];
				if (team.baseScore >= 950) nextleagues[0].teams.push(team);
				else if (team.baseScore >= 850) {
					if (team.home == "Dbg") nextleagues[1].teams.push(team);
					else nextleagues[5].teams.push(team);
				} else if (team.baseScore >= 750) {
					if (team.home == "Dbg") nextleagues[2].teams.push(team);
					else nextleagues[6].teams.push(team);
				} else if (team.baseScore >= 650) {
					if (team.home == "Dbg") nextleagues[3].teams.push(team);
					else nextleagues[7].teams.push(team);
				} else {
					if (team.home == "Dbg") nextleagues[4].teams.push(team);
					else nextleagues[8].teams.push(team);
				}
			}
			for (let j = 0; j < nextleagues.length; j++) {
				const nextleague = nextleagues[j];
				if (
					nextleague.teams.length % 6 != 0 &&
					nextleagues[j + 1] &&
					nextleague.location == nextleagues[j + 1].location
				) {
					var remove = Math.ceil(nextleague.teams.length / 6) * 6 - nextleague.teams.length;
					var overflow = 0;
					//fill to league
					for (let i = 0; i < remove; i++) {
						if (nextleagues[j + 1].teams[i]) nextleagues[j].teams.push(nextleagues[j + 1].teams[i]);
						else {
							overflow += 1;
						}
					}
					//remove teams filled up
					nextleagues[j + 1].teams.splice(0, remove - overflow);
				}
			}

			this.nextleague = nextleagues;

			this.leagues.forEach((league) => {
				league.teams = [];
				league.next_teams = [];
				this.teams.forEach((team) => {
					if (team.league_id == league.id) league.teams.push(team);
					if (team.next_league_id == league.id) league.next_teams.push(team);
				});
				league.teams.sort((a, b) => (a.position > b.position ? 1 : -1));
			});
		},
		setHome(team, home) {
			team.home = home;
			this.prepareTeams();
		},
		setLeague(team, baseScore) {
			team.baseScore = baseScore;
			team.newteam = true;
			team.location = team.next_location;
			team.name = team.next_name;
			var allowed = true;
			for (let i = 0; i < this.teamslist.length; i++) {
				const t = this.teamslist[i];
				if (t.id == team.id) allowed = false;
			}
			if (allowed) this.teamslist.push(team);
			this.prepareTeams();
		},
		removeTeam(team) {
			for (let i = 0; i < this.teamslist.length; i++) {
				const t = this.teamslist[i];
				if (t.id == team.id) this.teamslist.splice(i, 1);
			}
			this.prepareTeams();
		},
		toggleNotes() {
			this.showNotes = !this.showNotes;
		},
		toggleActions() {
			this.showActions = !this.showActions;
		},
	},
};
</script>
