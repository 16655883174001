var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spielplanSpiel row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h4',[_vm._v("Spiel Nr. "+_vm._s(_vm.spielnummer))])]),_vm._v(" "),_c('div',{staticClass:"col-6 text-right"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"#","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.toggleEasyMode()}}},[_vm._v("Eingabe umschalten")])])]),_vm._v(" "),_c('table',{staticClass:"table text-center match-table ml-1 mr-1"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('div',{class:{'winner': _vm.gamesHome == 2}},[_vm._v(_vm._s(_vm.playerHome.name))])]),_vm._v(" "),_c('th',{attrs:{"colspan":"2"}},[_c('div',{class:{'winner': _vm.gamesGuest == 2}},[_vm._v(_vm._s(_vm.playerGuest.name))])])]),_vm._v(" "),_c('tr',{staticClass:"saetze"},[_c('td',{class:{'winner': _vm.gamesHome == 2},attrs:{"colspan":"2"}},[(!_vm.easyMode)?_c('span',[_vm._v(_vm._s(_vm.gamesHome))]):_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gamesHome),expression:"gamesHome"}],attrs:{"type":"number","min":"0","max":"2"},domProps:{"value":(_vm.gamesHome)},on:{"click":_vm.selectField,"input":function($event){if($event.target.composing){ return; }_vm.gamesHome=$event.target.value}}})])]),_vm._v(" "),_c('td',{class:{'winner': _vm.gamesGuest == 2},attrs:{"colspan":"2"}},[(!_vm.easyMode)?_c('span',[_vm._v(_vm._s(_vm.gamesGuest))]):_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gamesGuest),expression:"gamesGuest"}],attrs:{"type":"number","min":"0","max":"2"},domProps:{"value":(_vm.gamesGuest)},on:{"click":_vm.selectField,"input":function($event){if($event.target.composing){ return; }_vm.gamesGuest=$event.target.value}}})])])]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),(_vm.overwriteWinner == null && !_vm.easyMode)?_c('tbody',[_c('tr',{class:{
                    'winner-home': _vm.game1guest.rest != 0,
                    'winner-away': _vm.game1home.rest != 0,
                    'error-leg-home-dart': _vm.errorInLeg(0, 'home'),
                    'error-leg-away-dart': _vm.errorInLeg(0, 'away'),
                    'error-leg-home-rest': _vm.errorInLeg(0, 'home', 'rest'),
                    'error-leg-away-rest': _vm.errorInLeg(0, 'away', 'rest'),
                    }},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game1home.darts),expression:"game1home.darts"}],attrs:{"type":"number"},domProps:{"value":(_vm.game1home.darts)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game1home, "darts", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game1home.rest),expression:"game1home.rest"}],attrs:{"type":"number"},domProps:{"value":(_vm.game1home.rest)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game1home, "rest", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game1guest.darts),expression:"game1guest.darts"}],attrs:{"type":"number"},domProps:{"value":(_vm.game1guest.darts)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game1guest, "darts", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game1guest.rest),expression:"game1guest.rest"}],attrs:{"type":"number"},domProps:{"value":(_vm.game1guest.rest)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game1guest, "rest", $event.target.value)}}})])]),_vm._v(" "),_c('tr',{class:{
                    'winner-home': _vm.game2guest.rest != 0,
                    'winner-away': _vm.game2home.rest != 0,
                    'error-leg-home-dart': _vm.errorInLeg(1, 'home'),
                    'error-leg-away-dart': _vm.errorInLeg(1, 'away'),
                    'error-leg-home-rest': _vm.errorInLeg(1, 'home', 'rest'),
                    'error-leg-away-rest': _vm.errorInLeg(1, 'away', 'rest'),
                }},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game2home.darts),expression:"game2home.darts"}],attrs:{"type":"number"},domProps:{"value":(_vm.game2home.darts)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game2home, "darts", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game2home.rest),expression:"game2home.rest"}],attrs:{"type":"number"},domProps:{"value":(_vm.game2home.rest)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game2home, "rest", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game2guest.darts),expression:"game2guest.darts"}],attrs:{"type":"number"},domProps:{"value":(_vm.game2guest.darts)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game2guest, "darts", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game2guest.rest),expression:"game2guest.rest"}],attrs:{"type":"number"},domProps:{"value":(_vm.game2guest.rest)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game2guest, "rest", $event.target.value)}}})])]),_vm._v(" "),_c('tr',{class:{
                    'winner-home': _vm.game3guest.rest != 0,
                    'winner-away': _vm.game3home.rest != 0,
                    'error-leg-home-dart': _vm.errorInLeg(2, 'home'),
                    'error-leg-away-dart': _vm.errorInLeg(2, 'away'),
                    'error-leg-home-rest': _vm.errorInLeg(2, 'home', 'rest'),
                    'error-leg-away-rest': _vm.errorInLeg(2, 'away', 'rest'),
                }},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game3home.darts),expression:"game3home.darts"}],attrs:{"type":"number"},domProps:{"value":(_vm.game3home.darts)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game3home, "darts", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game3home.rest),expression:"game3home.rest"}],attrs:{"type":"number"},domProps:{"value":(_vm.game3home.rest)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game3home, "rest", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game3guest.darts),expression:"game3guest.darts"}],attrs:{"type":"number"},domProps:{"value":(_vm.game3guest.darts)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game3guest, "darts", $event.target.value)}}})]),_vm._v(" "),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.game3guest.rest),expression:"game3guest.rest"}],attrs:{"type":"number"},domProps:{"value":(_vm.game3guest.rest)},on:{"click":_vm.selectField,"keydown":_vm.onInput,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.game3guest, "rest", $event.target.value)}}})])])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{staticClass:"w-25"},[_vm._v("Darts")]),_vm._v(" "),_c('th',{staticClass:"w-25"},[_vm._v("Rest")]),_vm._v(" "),_c('th',{staticClass:"w-25"},[_vm._v("Darts")]),_vm._v(" "),_c('th',{staticClass:"w-25"},[_vm._v("Rest")])])}]

export { render, staticRenderFns }