<template>
<div class="spielplanSpiel row">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <h4>Spiel Nr. {{spielnummer}}</h4>
            </div>
            <div class="col-6 text-right">
                <a href="#" class="btn btn-primary" @click.prevent="toggleEasyMode()" tabindex="-1">Eingabe umschalten</a>
            </div>
        </div>

        <table class="table text-center match-table ml-1 mr-1">
            <thead>
                <tr>
                    <th colspan="2">
                        <div :class="{'winner': gamesHome == 2}">{{playerHome.name}}</div>
                    </th>
                    <th colspan="2">
                    <div :class="{'winner': gamesGuest == 2}">{{playerGuest.name}}</div>
                    </th>
                </tr>

                <tr class="saetze">
                    <td colspan="2" class="" :class="{'winner': gamesHome == 2}">
                        <span v-if="!easyMode">{{gamesHome}}</span>
                        <span v-else>
                            <input  @click="selectField" type="number" v-model="gamesHome" min="0" max="2">
                        </span>
                    </td>
                    <td colspan="2" class="" :class="{'winner': gamesGuest == 2}">
                        <span v-if="!easyMode">{{gamesGuest}}</span>
                        <span v-else>
                            <input  @click="selectField" type="number" v-model="gamesGuest" min="0" max="2">
                        </span>
                    </td>
                </tr>
                <!-- <tr class="ohnespiel">
                    <td colspan="2">
                        <input type="checkbox" @click="setWinnerTo('home')" v-model="winnerhomewithoutgame" tabindex="-1" :id="'winnerhomewithoutgame'+game.id"> <label :for="'winnerhomewithoutgame'+game.id">Gewonnen ohne Spiel</label>
                    </td>
                    <td colspan="2">
                        <input type="checkbox" @click="setWinnerTo('away')" v-model="winnerawaywithoutgame" tabindex="-1" :id="'winnerawaywithoutgame'+game.id"> <label :for="'winnerawaywithoutgame'+game.id">Gewonnen ohne Spiel</label>
                    </td>
                </tr> -->
                <tr>
                    <th class="w-25">Darts</th>
                    <th class="w-25">Rest</th>
                    <th class="w-25">Darts</th>
                    <th class="w-25">Rest</th>
                </tr>
            </thead>
            <tbody v-if="overwriteWinner == null && !easyMode">
                <tr :class="{
                    'winner-home': game1guest.rest != 0,
                    'winner-away': game1home.rest != 0,
                    'error-leg-home-dart': errorInLeg(0, 'home'),
                    'error-leg-away-dart': errorInLeg(0, 'away'),
                    'error-leg-home-rest': errorInLeg(0, 'home', 'rest'),
                    'error-leg-away-rest': errorInLeg(0, 'away', 'rest'),
                    }">
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game1home.darts"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game1home.rest"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game1guest.darts"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game1guest.rest"></td>
                </tr>
                <tr :class="{
                    'winner-home': game2guest.rest != 0,
                    'winner-away': game2home.rest != 0,
                    'error-leg-home-dart': errorInLeg(1, 'home'),
                    'error-leg-away-dart': errorInLeg(1, 'away'),
                    'error-leg-home-rest': errorInLeg(1, 'home', 'rest'),
                    'error-leg-away-rest': errorInLeg(1, 'away', 'rest'),
                }">
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game2home.darts"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game2home.rest"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game2guest.darts"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game2guest.rest"></td>
                </tr>
                <tr :class="{
                    'winner-home': game3guest.rest != 0,
                    'winner-away': game3home.rest != 0,
                    'error-leg-home-dart': errorInLeg(2, 'home'),
                    'error-leg-away-dart': errorInLeg(2, 'away'),
                    'error-leg-home-rest': errorInLeg(2, 'home', 'rest'),
                    'error-leg-away-rest': errorInLeg(2, 'away', 'rest'),
                }">
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game3home.darts"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game3home.rest"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game3guest.darts"></td>
                    <td><input @click="selectField" @keydown="onInput" type="number" v-model="game3guest.rest"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
  components: {
  },
  props: {
        homeIndex: Number,
        guestIndex: Number,
        spielnummer: Number,
        game: Object,
        easyModeProp: Boolean
  },
  created() {
      this.playerHome = this.game.player1;
      this.playerGuest = this.game.player2;
      this.gamesWonHome = this.game.home_won;
      this.gamesWonAway = this.game.away_won;
    //   this.$emit('input', this.generateMatchData());

  },
  mounted() {

      this.$root.$on("toggleEasyMode", (mode) => {
          this.easyMode = mode;
      })

      if (this.game && this.game.legs) {
          if (this.game.legs[0]) {
            this.game1home = {darts: this.game.legs[0].player1_darts, rest: this.game.legs[0].player1_rest}
            this.game1guest = {darts: this.game.legs[0].player2_darts, rest: this.game.legs[0].player2_rest}
          }
          if (this.game.legs[1]) {
            this.game2home = {darts: this.game.legs[1].player1_darts, rest: this.game.legs[1].player1_rest}
            this.game2guest = {darts: this.game.legs[1].player2_darts, rest: this.game.legs[1].player2_rest}
          }
          if (this.game.legs[2]) {
            this.game3home = {darts: this.game.legs[2].player1_darts, rest: this.game.legs[2].player1_rest}
            this.game3guest = {darts: this.game.legs[2].player2_darts, rest: this.game.legs[2].player2_rest}
          }
      }

      if (this.game && this.game.legs && this.game.legs[0]) {
          if (this.game.winner1_id && this.game.legs[0].player1_darts == 0 && this.game.legs[1].player1_darts == 0 && this.game.legs[2].player1_darts == 0 ) {
              this.easyMode = true;
              this.$emit('input', this.generateMatchData());
          }
      }
      if (this.game.winner1_id && this.game && this.game.legs && this.game.legs.length == 0) {
          this.easyMode = true;
          this.$emit('input', this.generateMatchData());
      }

    //check for win without play
    //   if (this.game && this.game.legs && this.game.legs[0]) {
    //       if (this.game.winner1_id && this.game.legs[0].player1_darts == 0 && this.game.legs[1].player1_darts == 0 && this.game.legs[2].player1_darts == 0 ) {

    //           if (this.game.winner1_id == this.playerHome.id) {
    //               this.winnerhomewithoutgame = true;
    //               this.setWinnerTo('home');
    //           }
    //           if (this.game.winner1_id == this.playerGuest.id) {
    //               this.winnerawaywithoutgame = true;
    //               this.setWinnerTo('away');
    //           }
    //       }
    //   }
  },
  data() {
    return {
        playerHome: {},
        playerGuest: {},
        game1home: {darts: 0, rest: 0},
        game1guest: {darts: 0, rest: 0},
        game2home: {darts: 0, rest: 0},
        game2guest: {darts: 0, rest: 0},
        game3home: {darts: 0, rest: 0},
        game3guest: {darts: 0, rest: 0},
        overwriteWinner: null,
        winnerhomewithoutgame: null,
        winnerawaywithoutgame: null,
        winner: 0,
        easyMode: this.easyModeProp,
        gamesWonHome: 0,
        gamesWonAway: 0,
    }
  },
  computed: {
     gamesHome: {
         get: function() {
             if (this.easyMode) {
                 return this.gamesWonHome;
             } else {
                var games = 0;
                if (this.overwriteWinner == null) {
                    games += this.game1home.rest == 0 && this.game1guest.rest != 0 ? 1 : 0;
                    games += this.game2home.rest == 0 && this.game2guest.rest != 0 ? 1 : 0;
                    games += this.game3home.rest == 0 && this.game3guest.rest != 0 ? 1 : 0;
                } else {
                    games = this.overwriteWinner == this.playerHome ? 2 : 0;
                }
                this.onGamesChange(this.playerHome.id, games);
                return games;
            }
         },
         set: function(value) {
             this.gamesWonHome = parseInt(value);
             this.onGamesChange(this.playerHome.id, this.gamesWonHome);
         }
     },
     gamesGuest: {
         get: function() {
              if (this.easyMode) {
                 return this.gamesWonAway;
             } else {
                var games = 0;
                if (this.overwriteWinner == null) {
                    games += this.game1guest.rest == 0 && this.game1home.rest != 0 ? 1 : 0;
                    games += this.game2guest.rest == 0 && this.game2home.rest != 0 ? 1 : 0;
                    games += this.game3guest.rest == 0 && this.game3home.rest != 0 ? 1 : 0;
                } else {
                    games = this.overwriteWinner == this.playerGuest ? 2 : 0;
                }
                this.onGamesChange(this.playerGuest.id, games);
                return games;
             }
         },
         set: function(value) {
             this.gamesWonAway = parseInt(value);
             this.onGamesChange(this.playerGuest.id, this.gamesWonAway);
         }
     },
  },
  methods: {
      setWinnerTo(winner) {
          if (winner == 'home' && this.overwriteWinner == null) {
            this.overwriteWinner = this.playerHome;
            this.$emit('input', this.generateMatchData());
          } else if (winner == 'away' && this.overwriteWinner == null) {
            this.overwriteWinner = this.playerGuest;
            this.$emit('input', this.generateMatchData());
          } else {
            this.overwriteWinner = null;
          }
      },
      selectField() {
        document.execCommand("selectall",null,false);
      },
      onInput() {
        this.$emit('input', this.generateMatchData());
      },
      onGamesChange(team, games) {
          if (!this.easyMode && games == 2 && this.winner == 0) {
            this.winner = team
            this.$emit('input', this.generateMatchData());
          } else if (this.easyMode) {
            if (games >= 2) this.winner = team
            this.$emit('input', this.generateMatchData());
          }
      },
      generateMatchData() {
        var data = {};
        data['game_id'] = this.game.id;
        data['homeplayer'] = this.playerHome;
        data['guestplayer'] = this.playerGuest;
        if (!this.easyMode) {
            data['legs'] = [];
            data['legs'].push([this.game1home, this.game1guest]);
            data['legs'].push([this.game2home, this.game2guest]);
            data['legs'].push([this.game3home, this.game3guest]);
            data['winner'] = this.overwriteWinner != null ? this.overwriteWinner.id : this.winner;
        } else {
            data['gamesWonHome'] = this.gamesWonHome;
            data['gamesWonAway'] = this.gamesWonAway;
            data['legs'] = [];
            data['winner'] =  this.winner;
        }
        return data;
      },
      errorInLeg(legIndex, homeOrAway, dartOrRest = 'dart') {
        var hasError = false;
        var checkdata = undefined;
        var checkdata2 = undefined;

        var dataArrayHome = [this.game1home, this.game2home, this.game3home];
        var dataArrayAway = [this.game1guest, this.game2guest, this.game3guest];

        if (homeOrAway == 'home') {
            checkdata = dataArrayHome[legIndex];
            checkdata2 = dataArrayAway[legIndex];
        }
        if (homeOrAway == 'away') {
            checkdata = dataArrayAway[legIndex];
            checkdata2 = dataArrayHome[legIndex];
        }
        if (!hasError && dartOrRest == 'dart') hasError = this.checkError('tooFewDarts', checkdata)
        if (!hasError && dartOrRest == 'dart') hasError = this.checkError('dartsThrownSpan', checkdata, checkdata2)
        if (!hasError && dartOrRest == 'rest') hasError = this.checkError('doubleRestZero', checkdata, checkdata2)
        if (!hasError && dartOrRest == 'rest') hasError = this.checkError('doubleRestNotZero', checkdata, checkdata2)
        return hasError;
      },
      checkError(type, legdata, legdata2) {
          if (legdata.darts == 0) return false;
          if (type == 'tooFewDarts' && legdata.darts < 9) return true;
          if (type == 'dartsThrownSpan' && Math.abs(legdata.darts - legdata2.darts) > 3) return true;
          if (type == 'doubleRestZero' && legdata.rest == 0 && legdata2.rest == 0) return true;
          if (type == 'doubleRestNotZero' && legdata.rest != 0 && legdata2.rest != 0) return true;
      },
      toggleEasyMode() {
        this.easyMode = !this.easyMode;
      },
  }
}
</script>

<style lang="scss" scoped>
.spielplanSpiel {
    margin: 2vh 0 2vh 0;
}

input[type=number] {
    width: calc(100%);
    text-align: right;
}
</style>
