<template>
    <div class="fileupload row mt-4">
        <div class="col-12">
            <div class="card p-2">
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <label for="fileupload">Spielberichtsfoto</label>
                        <div v-if="match.report_checked">
                            Der Bericht wurde gepr&uuml;ft. Eine &Auml;nderung ist nicht mehr m&ouml;glich.
                        </div>
                        <div v-else>
                            <p>Hier kannst du ein Foto des unterschriebenen Spielberichts hochladen. Klicke auf das Foto f&uuml;r eine Vollbildansicht. Spielberichtsfoto sind nur &uuml;ber die Bearbeitungsseite einzusehen.</p>
                            <input type="file" class="form-control mb-2" name="foto" id="spielberichtsfoto" accept="image/*,application/pdf">
                            <a href="#" class="btn btn-primary btn-block" @click.prevent="onSubmit()">Spielberichtsfoto hochladen</a>
                            <a href="#" class="btn btn-danger btn-block" @click.prevent="onDelete()">Aktuelles Foto l&ouml;schen</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 text-center">
                        <div v-if="extension == 'png'">
                            <a :href="urlhelper(match.id)" target="_blank">
                                <img :src="urlhelper(match.id, 'png')" type="image/png" style="max-width: 100%; height: auto; max-height: 60vh"/>
                            </a>
                        </div>
                        <div v-if="extension == 'pdf'">
                            <object :data="urlhelper(match.id, 'pdf')" type="application/pdf" style="max-width: 100%; height: auto; max-height: 60vh"/>
                            <br>
                            <a :href="urlhelper(match.id,'pdf')" target="_blank">Vollbild Ansicht</a>
                        </div>
                        <div v-if="extension == ''">
                            <img src="/images/noreport.png" alt="Not found image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        props: {
            match: Object,
        },
        data() {
            return {
                url: '/matches/uploadfoto/'+this.match.id,
                foto: '',
                extension: ''
            }
        },
        mounted() {
            this.filefinder(this.match.id);
        },
        methods: {
            urlhelper(id, extension = "png") {
                return '/storage/spielberichte/spielbericht'+id+'.'+extension+'?' + Date.now();
            },
            filefinder(id) {
                axios.get('/storage/spielberichte/spielbericht'+id+'.png').then(response => {this.extension = 'png'}).catch(eror => {});
                axios.get('/storage/spielberichte/spielbericht'+id+'.pdf').then(response => {this.extension = 'pdf'}).catch(eror => {});
            },
            onSubmit () {
                var formData = new FormData();
                var imagefile = document.querySelector('#spielberichtsfoto');
                if (imagefile.files[0] != undefined) {
                    formData.append("image", imagefile.files[0]);
                    formData.append('_method', 'POST');
                    axios.post(this.url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        location.reload();
                    })
                    .catch(error => {
                        Vue.notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ein Fehler ist aufgetreten',
                            text: error
                        })
                    })
                }
            },
            onDelete () {
                var formData = new FormData();
                var imagefile = document.querySelector('#spielberichtsfoto');
                formData.append("image", null);
                formData.append('_method', 'POST');
                axios.post(this.url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    location.reload();
                })
                .catch(error => {
                    Vue.notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: error
                    })
                })
            }
        }
    }
</script>