<template>
    <form-builder :url="url" :method="method" :fields="fields"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/locations/merger/' + this.args.location.id,
                method: 'post',
                fields: [
                    {type: 'select', name: 'merger', label: 'Spielort', options: this.args.locations},
                    {type: 'submit', name: 'Zusammenlegen'}
                ]
            }
            
        }
    }
</script>