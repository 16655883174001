<template>
    <div>
        <div class="ergebnisse">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="w-20">Pass Nr.</th>
                        <th class="w-60">Name</th>
                        <th class="w-20 text-center">Trennen</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{user.player.passnr}}</td>
                    <td>{{user.player.name}}</td>
                    <td class="text-center">
                        <form method="post" @submit.prevent="remove(user.player)">
                            <button type="submit" class="btn btn-primary">Trennen</button>
                        </form>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                passnr: '',
                players: []
            }
        },
        props: {
            user: Object
        },
        methods: {
            search() {
                axios.get("/users/searchplayer?nr="+this.passnr)
                    .then(this.onSuccess)
            },
            onSuccess(response) {
                this.players = response.data.players;
            },
            remove(player) {
                var data = {};
                data.name = this.user.name;
                data.email = this.user.email;
                data.player_id = null;
                data._method = 'patch';
                axios.post('/users/'+this.user.id, data)
                    .then(this.onSuccessAdd)
            },
            onSuccessAdd() {
                window.location.reload();
            }
        }

    }
</script>
