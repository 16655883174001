<template>
    <form-builder :url="url" :method="method" :fields="fields" :argsdata="args.bug"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        created() {
            if (this.args.notitle) {
                this.fields = this.fields.filter(function( obj ) {
                    return obj.name !== 'title';
                });
           }
        },
        methods: {
        },
        data() {
            return {
                url: '/bugs/' + this.args.bug.id,
                method: 'patch',
                fields: [
                    {type: 'text', name: 'title', label: 'Titel'},
                    {type: 'textarea', name: 'description', label: 'Fehlerbeschreibung'},
                    {type: 'submit', name: 'Speichern'}
                ]
            }
            
        }
    }
</script>