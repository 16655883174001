<template>
    <form :action="url" method="post" @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)">
        <input type="hidden" name="_method" :value="method">
        <div v-for="field in fields" v-bind:key="field.name">
            <div v-if="Array.isArray(field)" class="row">
                <div v-for="subfield in field" v-bind:key="subfield.name" :class="['col-'+(Math.floor(12/field.length))]">
                    <form-builder-elements :field="subfield" :form="form"></form-builder-elements>
                </div>
            </div>
            <div v-else>
                <form-builder-elements :field="field" :form="form"></form-builder-elements>
            </div>
        </div>
        <notifications group="foo" position="top center" />
    </form>
</template>

<script>
import Form from '../form.js';
import FormBuilderElements from './FormBuilderElements';
    export default {
        components: {
            FormBuilderElements
        },
        created() {
            this.form.spinner = this.$refs.spinner;
            this.updateFormObjectValues();
        },
        data() {
            return {
                form: new Form({})
            }
        },
        props: {
            url: String,
            method: String,
            fields: Array,
            argsdata: Object,
            success: Function,
            failure: Function,
        },
        computed: {
        },
        methods: {
            updateFormObjectValues() {
                let formFields = {};
                this.fields.forEach(element => {
                    if (Array.isArray(element)) {
                        element.forEach(subelement => {
                            formFields[subelement.name] = subelement.value != undefined ? subelement.value : this.getValue(subelement.name);
                            if (!formFields[subelement.name] && this.argsdata && subelement.name in this.argsdata) {
                                formFields[subelement.name] = this.argsdata[subelement.name];
                            }
                        });
                    } else {
                        formFields[element.name] = element.value != undefined ? element.value : this.getValue(element.name);
                        if (!formFields[element.name] && this.argsdata && element.name in this.argsdata) {
                                formFields[element.name] = this.argsdata[element.name];
                            }
                    }
                });
                this.form.updateData(formFields);
            },
            onSubmit() {
                this.form.submit(this.method, this.url)
                    .then(data => {
                        this.success ? this.success(data) : '';
                    })
                    .catch(data => console.log(data));
            },
            reload() {
                this.updateFormObjectValues();
                this.$forceUpdate();
            },
            getValue(field) {
                return this.form.getValue(field);
            }
        }
    }
</script>
