
$(window).on('load', function() {
    $('.clickLoader').on('click', function() {
        var $this = $(this);
        $this.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + $this.html());
        $this.addClass('disabled');
    });

    //sort tables by a predefined column
    function sortByColumn() {
        if ($(".sortableTable").length == 0 ) return;
        $(".sortableTable").each((idx, table) => {
            var switchIndexDesc = $(table).find("th.sorted-desc").index();
            var switchIndexAsc = $(table).find("th.sorted-asc").index();
            var switchIndex = switchIndexDesc != -1 ? switchIndexDesc : switchIndexAsc != -1 ? switchIndexAsc : null;
            var switching = switchIndex != null;
            while (switching) {
                switching = false;
                rows = table.rows;
                for (i = 1; i < (rows.length - 1); i++) {
                    shouldSwitch = false;
                    x = rows[i].getElementsByTagName("TD")[switchIndex];
                    y = rows[i + 1].getElementsByTagName("TD")[switchIndex];
                    if (switchIndexDesc != -1) {
                        if (parseFloat(x.innerHTML.toLowerCase()) < parseFloat(y.innerHTML.toLowerCase())) {
                            shouldSwitch = true;
                            break;
                        }
                    }
                    else if (switchIndexAsc != -1) {
                        if (parseFloat(x.innerHTML.toLowerCase()) < parseFloat(y.innerHTML.toLowerCase())) {
                            shouldSwitch = true;
                            break;
                        }
                    }
                }
                if (shouldSwitch) {
                    rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                }
            }
            //update indexes
            $(table).find("td.count-asc").each((idx, td) => {
                $(td).html((idx+1));
            })
        });
    }
    sortByColumn();
});