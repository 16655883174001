<template>
    <form-builder :url="url" :method="method" :fields="fields" ref='form' :argsdata="args.season"></form-builder>
</template>

<script>
    import FormBuilder from './FormBuilder';
    export default {
        components: {
            FormBuilder
        },
        props: {
            args: Object
        },
        data() {
            return {
                url: '/seasons/' + this.args.season.id,
                method: 'patch',
                fields: [
                    {type: 'text', name: 'name', label: 'Saison Name (Bsp. I/20)'},
                    {type: 'datetime', name: 'startDate', label: 'Startdatum'},
                    {type: 'datetime', name: 'endDate', label: 'Enddatum'},
                    {type: 'submit', name: 'Speichern'}
                ]
            }
        }
    }
</script>